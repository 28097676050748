export default {
  en: {
    signIn: 'Sign In with SSO',
    return: 'Back to Password Sign-in',
    tryAgain: 'Try again',
    invalid: 'Please enter a valid email address.',
    error: 'Failed to Sign-in with SSO. Please try again.',
    failed: 'Failed to Sign-in with SSO',
    apiError: 'Oops something went wrong',
    signingIn: 'Signing in...',
    ssoCallbackTitle: 'Signing in',
    sso_enforced: 'Your organisation has enforced Single Sign-On. Please login below.',
    account_locked: `Your account has been locked. Please contact your team lead or
    a customer success manager to unlock your account.`,
    oauth: {
      failed: 'Failed to Sign-in with Google',
      apiError:
        'Make sure to use the same email that you registered with on Risk Ledger, and that your organisation Admins have enabled Google OAuth Sign-In in the Risk Ledger settings.',
    },
  },
};
