import config from '@/config/widgets';
import { request } from '@/api/request';
import type { TrialResponse } from '@/modules/trial/api';
import type AnalyticsModule from './AnalyticsModule';

const getIntercomUserHash = () => request('GET', '/user/intercom');

const setUserData = async (user: any) => {
  if (user && window.intercomSettings) {
    window.intercomSettings.user_id = user.userID;
    window.intercomSettings.name = `${user.firstName} ${user.lastName}`;
    window.intercomSettings.phone = user.phone;
    window.intercomSettings.email = user.email;

    window.intercomSettings.createdAt = user.createdAt;
    window.intercomSettings.activatedAt = user.activatedAt;
    window.intercomSettings.status = user.status;
    window.intercomSettings.role = user.role;
    window.intercomSettings.team = user.team;

    window.intercomSettings.godUser = `${config.appUrl}/zeus/users/${user.userID}`;

    const res = await getIntercomUserHash();
    window.intercomSettings.user_hash = res.data.userHash;
  }
};

const setOrgData = (org: any, status: any, trial?: TrialResponse) => {
  if (org && window.intercomSettings) {
    window.intercomSettings.company = {
      name: org.name,
      company_id: org.orgID,
      website: org.website,
      isSupplier: org.isSupplier,
      isClient: org.isClient,
      paid: org.paid,
      assessmentProgress: status ? status.assessmentProgress : '',
      initialAssessmentComplete: status ? status.initialAssessmentComplete : '',
      scopingComplete: status ? status.scopingComplete : '',
      trialType: trial ? trial.trialType : '',
    };

    window.intercomSettings.avatar = {
      type: 'avatar',
      image_url: org.logoUrl,
    };
  }
};

/* eslint class-methods-use-this: 0 */
export default class implements AnalyticsModule {
  constructor() {
    window.intercomSettings = {
      app_id: config.intercomAppID,
    };

    // Intercom widget code below.
    (function () {
      const w = window as any;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = function (...args: any[]) {
          i.c(args);
        } as any;
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${config.intercomAppID}`;
          const x = d.getElementsByTagName('script')[0] as any;
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();
  }

  trackEvent(eventName: string, metaObject: Record<string, any>) {
    window.Intercom('trackEvent', eventName, metaObject);
  }

  trackView() {
    window.Intercom('update');
  }

  async setUserProperties(params: any) {
    const { user, org, status, trial } = params;
    await setUserData(user);
    setOrgData(org, status, trial);
    window.Intercom('update', window.intercomSettings);
  }
}
