import { until } from '@vueuse/core';
import type { NavigationGuard } from 'vue-router';
import { useCurrentOrg } from '@/modules/currentOrg/composables';
import { useTrial } from '@/modules/trial/composables';
import type { TrialType } from '@/modules/trial/api';

// If current org is not client, redirect to '/',
// else continue to requested route
export const requireClient: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();
  await scope.run(async () => {
    const { trial, isTrialEnded } = useTrial();

    const { isClient } = useCurrentOrg();
    if (!(await isClient())) {
      next('/');
      return;
    }

    if (trial.value === undefined) {
      await until(trial).toMatch((c) => typeof c !== 'undefined');
    }

    if (isTrialEnded.value) {
      next({ name: 'client-trial-ended' });
      return;
    }

    next();
  });

  scope.stop();
};

// If current org is not supplier, redirect to '/',
// else continue to requested route
export const requireSupplier: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();
  await scope.run(async () => {
    const { isSupplier } = useCurrentOrg();
    if (!(await isSupplier())) {
      next('/');
      return;
    }
    next();
  });

  scope.stop();
};

// If current org is supplier or client redirect to '/'
// else continue to route as org has no type yet
export const requireNoType: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();
  await scope.run(async () => {
    const { isClient, isSupplier } = useCurrentOrg();
    if ((await isClient()) || (await isSupplier())) {
      next('/');
      return;
    }
    next();
  });

  scope.stop();
};

// If current org is not federated, redirect to '/',
// else continue to requested route.
// May need to consider trial status at a later stage.
export const requireFederated: NavigationGuard = async (_to, _from, next) => {
  const { isFederated } = useCurrentOrg();
  if (!(await isFederated())) {
    next('/');
    return;
  }

  next();
};

// If current org is federated, redirect to '/',
// else continue to requested route.
// May need to consider trial status at a later stage.
export const requireNotFederated: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();
  await scope.run(async () => {
    const { isFederated } = useCurrentOrg();
    if (await isFederated()) {
      next('/');
      return;
    }

    next();
  });

  scope.stop();
};

// Checks if org is any of the given types.
// Does not require that mode to be the current one.
// In the case of client being one of the required types, it does NOT check for trial status.
export function requireAnyType(typeList: ('supplier' | 'client' | 'federated')[]) {
  return (async (_to, _from, next) => {
    const scope = effectScope();
    await scope.run(async () => {
      const { isClient, isSupplier, isFederated } = useCurrentOrg();

      if (
        (typeList.includes('client') && (await isClient())) ||
        (typeList.includes('supplier') && (await isSupplier())) ||
        (typeList.includes('federated') && (await isFederated()))
      ) {
        next();
        return;
      }

      next('/');
    });
    scope.stop();
  }) satisfies NavigationGuard;
}

// If current org is restricted from the client side and is a supplier,
// redirect to '/s' for the supplier side
// else continue to requested route
export const requireClientNotRestricted: NavigationGuard = async (_to, _from, next) => {
  const scope = effectScope();
  await scope.run(async () => {
    const { isSupplier, isClientRestricted } = useCurrentOrg();
    if ((await isSupplier()) && (await isClientRestricted())) {
      next('/s');
      return;
    }
    next();
  });
  scope.stop();
};

export const requireTrialType =
  (trialType: TrialType): NavigationGuard =>
  async (_to, _from, next) => {
    const scope = effectScope();
    await scope.run(async () => {
      const { trial, isOnTrialType } = useTrial();

      if (trial.value === undefined) {
        await until(trial).toMatch((c) => typeof c !== 'undefined');
      }

      if (!isOnTrialType(trialType)) {
        next({ name: 'client-dashboard' });
        return;
      }

      next();
    });

    scope.stop();
  };
