import { useQuery, useMutation } from '@tanstack/vue-query';
import { useLocalStorage } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import type { MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import type { ResendSignUpCodeOutput, ConfirmSignUpOutput, SignUpOutput } from 'aws-amplify/auth';
import cognito from '@/legacy/auth/cognito';
import {
  whenNonNull,
  mergeMutationOptions,
  mergeQueryOptions,
  type QueryOptions,
} from '@/modules/query/utils';
import type { User } from '@/types/models/user';
import type { Organisation } from '@/types/models/organisation';
import api, { type InviteResponse, type PublicSupplierResponse } from './api';
import { PUBLIC_SUPPLIER_QUERY, INVITE_QUERY } from './composableKeys';

// TODO: I don't think this is the right place for this… (15 minutes)
export function usePublicSupplier(
  id: MaybeRefOrGetter<string>,
  options?: QueryOptions<PublicSupplierResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [PUBLIC_SUPPLIER_QUERY, toRef(id)],
      async queryFn({ queryKey }) {
        const res = await api.fetchPublicSupplier(queryKey[1]);
        return res.data;
      },
    }),
  );

  return { ...query, supplier: query.data };
}

export function useInvite(
  id: MaybeRefOrGetter<string>,
  options?: QueryOptions<InviteResponse, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [INVITE_QUERY, toRef(id)],
      async queryFn({ queryKey }) {
        const res = await api.fetchInvite(queryKey[1]);
        return res.data;
      },
    }),
  );

  return { ...query, invite: query.data };
}

export function useSignupSupplier() {
  // This localstorage item is populated by the catchSignupSource router guard
  const state = useLocalStorage('signupSource', undefined as string | undefined);

  const source = computed(() => {
    if (!state.value) return undefined;

    const parsed = JSON.parse(state.value);

    return typeof parsed === 'object' &&
      'id' in parsed &&
      typeof parsed.id === 'string' &&
      'type' in parsed &&
      typeof parsed.type === 'string'
      ? (parsed as { id: string; type: string })
      : undefined;
  });

  const {
    invite,
    isLoading: isInviteLoading,
    isError: isInviteError,
  } = whenNonNull(
    () => source.value?.id,
    (enabled, id) => useInvite(id, { enabled }),
  );

  const supplierQuery = whenNonNull(
    () => invite.value?.unclaimedOrgID,
    (enabled, unclaimedOrgID) =>
      usePublicSupplier(unclaimedOrgID, {
        enabled: () => !invite.value?.cancelled && !invite.value?.expired && enabled(),
      }),
  );

  const isLoading = computed(() => isInviteLoading.value || supplierQuery.isLoading.value);
  const isError = computed(() => isInviteError.value || supplierQuery.isError.value);

  return { ...supplierQuery, isLoading, isError, invite, source };
}

export function useCreateNewOrg(
  options?: MutationObserverOptions<
    { org: Organisation; user: User },
    AxiosError,
    { org: Partial<Organisation>; user: Partial<User> },
    null
  >,
) {
  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(variables) {
        const res = await api.createNewOrg(variables.org, variables.user);
        return res.data;
      },
    }),
  );
}

export function useClaimOrg(
  options?: MutationObserverOptions<
    { org: Organisation; user: User },
    AxiosError,
    { org: Partial<Organisation>; user: Partial<User> },
    null
  >,
) {
  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(variables) {
        const res = await api.claimOrg(variables.org, variables.user);
        return res.data;
      },
    }),
  );
}

export function useCreateNewUser() {
  return useMutation<
    SignUpOutput,
    Error,
    { user: User; password: string; org: Organisation; inviteID: string | undefined },
    null
  >({
    mutationFn({ user, password, org, inviteID }) {
      return cognito.signUp({
        user_id: user.userID,
        email: user.email,
        password,
        given_name: user.firstName,
        family_name: user.lastName,
        phone_number: user.phone,
        organisation_id: org.orgID,
        invite: inviteID || '',
        marketing: user.marketingEmails,
      });
    },
  });
}

export function useConfirmSignup() {
  return useMutation<ConfirmSignUpOutput, Error, { username: string; code: string }, null>({
    mutationFn({ username, code }) {
      return cognito.confirmSignup(username, code);
    },
  });
}

export function useResendCode() {
  return useMutation<ResendSignUpCodeOutput, Error, { email: string }, null>({
    mutationFn({ email }) {
      return cognito.resendCode(email);
    },
  });
}

export function useSignIn() {
  return useMutation<any, Error, { username: string; password: string }, null>({
    mutationFn({ username, password }) {
      return cognito.signin(username, password);
    },
  });
}

export function useTeamOptions() {
  const { t } = useI18n();

  return computed(() => [
    { label: t('teams.sales'), value: 'sales' },
    { label: t('teams.security'), value: 'security' },
    { label: t('teams.compliance'), value: 'compliance' },
    { label: t('teams.procurement'), value: 'procurement' },
    { label: t('teams.legal'), value: 'legal' },
    { label: t('teams.esg'), value: 'esg' },
    { label: t('teams.it'), value: 'it' },
    { label: t('teams.other'), value: 'other' },
  ]);
}
