export default {
  en: {
    page: {
      pageTitle: 'Risks',
      title: 'Risks',
      fetchError: 'We were unable to load your risks, please refresh the page & try again.',
      openRisk: 'Open Risk',
      viewReporting: 'View Reporting',
      learnMore: 'Learn More',
    },
    friendlyID: 'Risk-{0}',
    status: {
      OPEN: 'Open',
      CLOSED: 'Closed',
    },
    openModal: {
      title: 'Open a Risk',
      name: 'Name',
      namePlaceholder: 'Summarise the risk...',
      description: 'Description',
      descriptionPlaceholder: 'Describe the risk...',
      supplier: 'Supplier Name',
      organisation: 'Organisation',
      relation: 'Relation',
      open: 'Open',
      cancel: 'Cancel',
      openingFailed: 'Failed to open risk. Please try again or contact Risk Ledger support.',
      openingSucceeded: 'Opened {risk}.',
      supplierPlaceholder: 'Select a Supplier...',
      subEntityPlaceholder: 'Select a Sub-Entity...',
      likelihood: 'Likelihood',
      impact: 'Impact',
      score: 'Risk Score',
    },
    table: {
      columns: {
        friendlyID: 'ID',
        name: 'Name',
        description: 'Description',
        supplier: 'Supplier',
        organisation: 'Organisation',
        status: 'Status',
        assignedUsers: 'Assigned',
        riskOwner: 'Risk Owner',
        opened: 'Opened',
        modified: 'Last Modified',
        closed: 'Closed',
        impact: 'Impact',
        likelihood: 'Likelihood',
        riskScore: 'Risk Score',
        riskType: 'Risk Type',
      },
      emptyTitle: 'No risks',
      errorTitle: 'Failed to load risks',
      errorSubtitle: 'Please refresh the page and try again',
      riskTypes: {
        concentration: 'Concentration Risk',
        generic: 'Generic Risk',
        control: 'Control',
        supplier: 'Supplier',
        sub_entity: 'Sub-Entity',
        asset_scan: 'Asset Scan',
        asset: 'Asset',
      },
    },
    filter: {
      entityName: 'Risk',
      results: '{count} Risk | {count} Risks',
      resultsOutOf: '{0} out of {1}',
      attributes: {
        friendlyID: 'ID',
        name: 'Name',
        supplier: 'Supplier',
        organisation: 'Organisation',
        status: 'Status',
        assignedUsers: 'Assigned Users',
        riskOwner: 'Risk Owner',
        openedAt: 'Opened Date',
        openedBy: 'Opened By',
        modifiedAt: 'Last Modified Date',
        modifiedBy: 'Last Modified By',
        closedAt: 'Closed Date',
        closedBy: 'Closed By',
        impact: 'Impact',
        likelihood: 'Likelihood',
        riskScore: 'Risk Score',
        riskType: 'Risk Type',
      },
    },
    export: {
      button: 'Export',
      error: 'Failed to export risks, please try again.',
      filename: 'risks-{date}.csv',
      dateFormat: 'yyyyMMdd',
    },
    sidePanel: {
      errorLoading: 'There was an error loading this risk',
      errorLoadingNotFound: 'Risk not found, there is no risk with the identifier {risk}',
      errorSaving: 'There was an error saving this risk',
      errorClosing: 'There was an error closing this risk',
      errorReopening: 'There was an error re-opening this risk',
      errorDeleting: 'There was an error deleting this risk',
      name: 'Name',
      description: 'Description',
      assignedUsers: 'Assigned Users',
      riskOwner: 'Risk Owner',
      organisation: 'Organisation',
      organisationNotConnected:
        'Your organisation is no longer connected to this sub-entity. Re-connect with them in order to see their profile.',
      supplier: 'Supplier',
      supplierNotConnected:
        'Your organisation is no longer connected to this supplier. Re-connect with them in order to see their profile.',
      opened: 'Opened',
      modified: 'Modified',
      edit: 'Edit',
      delete: 'Delete',
      close: 'Close Risk',
      closed: 'Closed',
      reopen: 'Re-Open Risk',
      save: 'Save',
      cancel: 'Cancel',
      confirm: 'Confirm',
      deleteTitle: 'Delete Risk: {name}',
      deleteDescription: 'Please confirm that you would like to delete the risk.',
      retryFetch: 'Retry',
      placeholders: {
        name: 'Name...',
        description: 'Risk description...',
        riskOwner: 'Risk owner...',
        assignedUsers: 'Assigned users...',
      },
      concentrationRisk: 'Concentration Risk',
      viewNetwork: 'View Network',
    },
    networkStats: {
      degree: 'Connectivity Degree',
      federatedDegreeTooltip:
        'Degree is how far down the supply chain {org} is from your organisation. Your sub-entities are degree 1, their immediate suppliers are degree 2, and so on.',
      clientDegreeTooltip:
        'Degree is how far down the supply chain {org} is from your organisation. Your immediate suppliers are degree 1, their immediate suppliers are degree 2, and so on.',
      subEntities: 'Sub-Entities',
      subEntitiesTooltip:
        'Number of sub-entities that have {org} somewhere in their supply chain, either as a direct supplier or further down.',
      clients: 'Clients',
      federatedClientsTooltip:
        'Number of organisations in your federated network that have {org} as a direct supplier.',
      clientClientsTooltip:
        'Number of organisations in your network that have {org} as a direct supplier.',
    },
    content: {
      archived: 'The associated answer has been archived',
    },
    supplierProfile: {
      placeholders: {
        emptyTitle: 'No risks',
        emptySubtitle: 'No risks have been opened against this supplier',
      },
      openRisk: 'Open Risk',
    },
    emptyPlaceholder: {
      title: 'Track & Manage your Risks',
      description: `When you review your suppliers' security posture, open and track any risks that you've identified with them, on a specific control, or generic risks not tied to a specific supplier.`,
      federatedDescription: `When you review your Sub-Entities security posture, open and track any risks that you've identified with them or create generic risks not tied to a specific Sub-Entity organisation.`,
      openRisk: 'Open Risk',
    },
    controlRisks: {
      risks: 'Risks',
      openRisk: 'Open Risk',
      view: 'View',
    },
    activityItems: {
      empty: '—',
      from: ' from {from}',
      to: ' to {to}',
      name: 'name',
      description: 'description',
      owner: 'Risk Owner',
      riskOpened: '{name} opened {risk}',
      riskClosed: '{name} closed {risk}',
      riskReopened: '{name} re-opened {risk}',
      riskDeleted: '{name} deleted {risk}',
      riskSomethingModified: '{name} changed the {thing}{from}{to}',
      riskUserAssigned: '{name} assigned {assignedUser}',
      riskUserUnassigned: '{name} unassigned {unassignedUser}',
      riskFileUploaded: '{name} uploaded file {filename}',
      riskFileRemoved: '{name} removed file {filename}',
      riskMatrix: {
        x: 'likelihood',
        y: 'impact',
      },
      notePlaceholder: 'Leave a note on the risk...',
      noteEmpty: `Notes can't be empty`,
      noteError: 'There was an error saving the note',
      addNote: 'Add Note',
      notePrivacy: 'Only visible to {0}',
    },
    settings: {
      title: 'Risks',
      fetchError: 'Failed to get risks preferences',
      updateError: 'Failed to update risks preferences',
      learnMore: 'Learn More',
      riskScoringRequired: {
        label: 'Risk Scores',
        description: 'When required, all risks must be opened with impact and likelihood scores.',
        optional: 'Optional',
        required: 'Required',
      },
      riskScoringCalculation: {
        label: 'Risk Score Strategy',
        description:
          'Choose whether multiplication or addition is used when calculating risk scores.',
        multiplication: 'Multiplication',
        addition: 'Addition',
      },
      riskLevels: {
        fetchError: 'Failed to get risk levels',
        updateError: 'Failed to update risk levels',
        createError: 'Failed to create risk level',
        deleteError: 'Failed to delete risk level',
        deleteErrorUsed:
          'You cannot remove the {position} "{levelName}" {levelType} level at the moment because it is being used within a risk. Before you can remove this level, you must first remove it from all existing risks.',
        label: 'Customise your Levels',
        description:
          'Add, rename, reorder or delete your impact and likelihood levels below. Each level must have a unique name. You can have a minimum of 2 levels and a maximum of 5 levels.',
      },
      riskMatrix: {
        label: 'Preview your Risk Matrix',
        likelihoodAxis: 'Likelihood',
        impactAxis: 'Impact',
      },
    },
    trialPlaceholder: {
      title: 'Track and manage your Risks',
      description:
        "When you review your suppliers, you can open and track any risks that you've identified. Risks can be assigned to a specific control, or you can create generic risks.",
      title2: 'Key benefits',
      benefit1: 'Prioritise suppliers based on security posture and business impact',
      benefit2: 'Track risk trends with visual reporting',
      benefit3: 'Identify security gaps across different control domains',
      benefit4: 'Generate board-level reports showing your proactive risk management approach',
      cta: 'Book a demo',
    },
  },
};
