export enum EngagementAssociatedType {
  User = 'USER',
  Organisation = 'ORGANISATION',
}

export enum EngagementType {
  AddMonitoringDomain = 'MONITORING_ADD_DOMAIN',
  MonitoringBenefits = 'MONITORING_BENEFITS',
  PLGHubSpotForm = 'PLG_HUBSPOT_FORM',
  SupplierAssessmentPdfExported = 'SUPPLIER_ASSESSMENT_PDF_EXPORTED',
  SupplierAssessmentCsvExported = 'SUPPLIER_ASSESSMENT_CSV_EXPORTED',
  SupplierAssessmentApproved = 'SUPPLIER_ASSESSMENT_APPROVED',
  SupplierAssessmentRejected = 'SUPPLIER_ASSESSMENT_REJECTED',
  SupplierConnectionRequested = 'SUPPLIER_CONNECTION_REQUESTED',
}

export type Engagement = {
  id: string;
  associatedType: EngagementAssociatedType;
  associatedID: string;
  engagementType: EngagementType;
  engagementCount: number;
  lastSessionID: string;
};
