export default {
  en: {
    headers: {
      pageTitle: `{supplierName}'s Documents`,
      headerTitle: 'Documents',
    },
    uploader: {
      title: 'Add a new document',
      description: 'Drag and drop, or select a file',
      button: 'Upload New',
      upload: 'Upload',
      select: 'Select Existing',
      attach: 'Attach evidence',
      suggestionsAvailable: 'Suggestions available',
    },
    answers: {
      docEvidence: 'Document evidence',
    },
    preview: {
      download: 'Download',
      rename: 'Rename',
      history: 'History',
      replace: 'Replace',
      delete: 'Delete',
      deleteModalTitle: 'Delete Document "{name}"?',
      deleteModalDescription:
        "Please confirm if you would like to delete '{name}'. It will also be removed from all answers it is currently attached to.",
      retry: 'Retry',
      setPrivate: 'Hide from clients',
      setPublic: 'Make visible to clients',
      remove: 'Remove',
    },
    previewSidePanel: {
      info: 'Info',
      history: 'History',
      comments: 'Comments',
      about: 'About',
      document: 'Document',
      fileSize: 'File Size',
      fileType: 'File Type',
      attachments: 'Attachments ({count})',
      documentHistory: 'Document History',
    },
    attachments: {
      control: 'Control {controlCode}',
      threatQuestion: '{threatName} Q.{questionNumber}',
      emergingThreat: 'Emerging Threat',
    },
    renameModal: {
      title: 'Rename Document "{name}"?',
      newName: 'New Document Name',
      oldName: 'Original Document Name',
    },
    scanStatus: {
      scanning: 'Processing file',
      rejected: 'Failed to process file, please contact support',
    },
    attachModal: {
      title: 'Select a Document',
      close: 'Close',
      save: 'Save Changes',
      searchPlaceholder: 'Search Documents',
      emptySearchTitle: 'No Documents',
      emptySearchSubtitle: 'No documents currently match your search',
      suggested: 'Suggested',
    },
    suggested: {
      suggestedDocuments: 'Suggested Documents',
      latestDocuments: 'Latest Documents',
    },
    history: {
      title: 'Version History',
      types: {
        document_replaced: 'Document Replaced',
        document_renamed: 'Document Renamed',
        document_created: 'Document Created',
        attachment_created: 'Document Attached',
        attachment_removed: 'Document Detached',
        document_set_private: 'Document marked not visible for Clients',
        document_set_not_private: 'Document marked as visible to Clients',
      },
      byName: 'by {name}',
    },
    attachedOn: 'Attached on:',
    placeholder: {
      title: 'There are no documents.',
      description: 'Your organisation currently has no documents to display.',
    },
    setPrivateStatusModal: {
      title: 'Set Document Visibility',
      descriptionPrivate:
        'Please confirm if you would like to make the document "{documentName}" not visible to clients.',
      descriptionNotPrivate:
        'Please confirm if you would like to make the document "{documentName}" visible to clients.',
      makePrivate: 'Make not visible to clients',
      makeNotPrivate: 'Make visible to clients',
      failed: 'Failed to update document visibility',
      cantSetPrivate:
        'This document cannot be made visible to clients as it is attached to one or more answers. Remove all the attachments before trying again.',
    },
    tooltips: {
      private: 'This document is not visible to Clients',
    },
  },
};
