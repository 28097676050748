import type { MutationTree } from 'vuex';
import type { Answer } from '@/types/models/answer';
import type { ScopingAnswer } from '@/types/models/scopinganswer';
import type { ControlBookmark } from '@/types/models/control';
import type { AssessmentStatus } from '@/types/models/assessmentstatus';
import type { ReassessmentStatus } from '@/types/models/reassessment';

const initialState = () => ({
  controlAnswers: {} as Record<string, Answer>,
  controlBookmarksByControlID: {} as Record<string, ControlBookmark>,
  scopingAnswers: {} as Record<string, ScopingAnswer>,
  status: {} as AssessmentStatus | Record<string, never>,
  reassessmentStatus: {} as ReassessmentStatus,
});

export const state = initialState();
export type State = typeof state;

export const mutations: MutationTree<State> = {
  SET_ASSESSMENT_STATUS(state, data: State['status']) {
    // Round assessment status percentage down to an integer.
    data.assessmentProgress = Math.floor(data.assessmentProgress);
    state.status = data;
  },
  SET_REASSESSMENT_STATUS(state, data: State['reassessmentStatus']) {
    state.reassessmentStatus = data;
  },
  SET_SCOPING_ANSWERS(state, data: State['scopingAnswers']) {
    state.scopingAnswers = data;
  },
  CHANGE_SCOPING_ANSWER(state, data: ScopingAnswer) {
    state.scopingAnswers[data.domainID] = data;
  },
  SET_CONTROL_ANSWERS(state, data: State['controlAnswers']) {
    state.controlAnswers = data;
  },
  SET_CONTROL_BOOKMARKS(state, bookmarks: ControlBookmark[]) {
    state.controlBookmarksByControlID = Object.fromEntries(
      bookmarks.map((bookmark) => [bookmark.controlID, bookmark]),
    );
  },
  SET_CONTROL_BOOKMARK(state, data: ControlBookmark) {
    state.controlBookmarksByControlID[data.controlID] = data;
  },
  START_REASSESSMENT(state, data: ReassessmentStatus) {
    state.reassessmentStatus.status = data.status;
  },
  CLEAR_STATE(state) {
    Object.assign(state, initialState());
  },
};
