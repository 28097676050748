import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';
import { requireInitialAssessmentNotComplete } from './guards/assessment';

export default [
  {
    path: 'getting-started',
    name: 'supplier-getting-started',
    component: () => import('@/modules/mode/GettingStarted.vue'),
    beforeEnter: requireInitialAssessmentNotComplete,
  },
  {
    path: 'clients/:id',
    name: 'clients-profile',
    component: () => import('@/modules/profile/ClientProfile.vue'),
    props: true,
    redirect: { name: 'clients-profile-overview' },
    meta: {
      permissions: [Permission.SuppliersClientsView],
    },
    children: [
      {
        name: 'clients-profile-overview',
        path: 'overview',
        component: () => import('@/modules/profile/components/overview/ClientOverviewTab.vue'),
      },
      {
        name: 'clients-profile-activity',
        path: 'activity',
        component: () => import('@/modules/activityFeed/ActivityFeedConnection.vue'),
      },
      {
        name: 'clients-profile-assessment',
        path: 'assessment',
        component: () => import('@/modules/profile/components/assessment/AssessmentTab.vue'),
      },
      {
        name: 'clients-profile-threats',
        path: 'threats/:threatId?',
        component: () => import('@/modules/emergingThreatsCommon/ThreatsProfileTab.vue'),
        props: true,
      },
      {
        name: 'clients-profile-discussions',
        path: 'discussions/:discussionID?',
        component: () => import('@/modules/discussions/DiscussionsTabView.vue'),
        props: (route) => ({
          discussionId: route.params.discussionID,
          query: route.query,
        }),
      },
      {
        name: 'clients-profile-about',
        path: 'about',
        component: () => import('@/modules/orgInfo/OrgInfo.vue'),
      },
    ],
  },
] satisfies RouteRecordRaw[];
