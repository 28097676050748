import { discussionMatchesOperand } from '../utils';
import { CommentStatus } from './mutations';

// Get object of discussionID mapped to discussions
const discussionsByID = (state: any) => state.discussions.byID;
const discussionByID = (state: any) => (discussionID: string) =>
  state.discussions.byID[discussionID];

const discussionsArray = (state: any) =>
  state.discussions.allIDs.map((id: string) => state.discussions.byID[id]);

const discussionPreviews = (state: any) => state.discussionPreviews;

const discussionIDsWithOrg = (state: any) => (orgID: string) =>
  state.discussionLists.byOrgID[orgID];

// Get object of associatedID mapped to array of discussions
const discussionsIDByAssocID = (state: any) => state.discussionLists.byAssociatedID;
const discussionsByAssocID = (state: any, getters: any) => {
  const obj: Record<string, any> = {};
  for (const key of Object.keys(getters.discussionsIDByAssocID)) {
    obj[key] = getters.discussionsIDByAssocID[key].map(
      (discussionId: string) => state.discussions.byID[discussionId],
    );
  }
  return obj;
};

// Get active discussions ordered by last comment
const activeDiscussions = (_: any, getters: any) => {
  const obj = getters.discussionsArray.filter((discussion: any) => discussion.archivedAt == null);
  obj.sort((a: any, b: any) => {
    a = new Date(a.comments[a.comments.length - 1].createdAt);
    b = new Date(b.comments[b.comments.length - 1].createdAt);
    return b - a;
  });
  return obj;
};

const userByID = (state: any) => (userId: string) => state.users[userId];

const userNameByID = (state: any) => (userId: string) => {
  const user = state.users[userId];
  return `${user?.firstName} ${user?.lastName}`;
};

const activeDiscussionsWithOrgIDs = (state: any, getters: any) => (orgID: string) => {
  if (state.discussionLists.byOrgID[orgID] == null) return [];
  return state.discussionLists.byOrgID[orgID].filter(
    (discussionID: string) => getters.discussionsByID[discussionID].archivedAt == null,
  );
};

const activeDiscussionsWithOrg = (_: any, getters: any) => (orgID: string) =>
  getters
    .activeDiscussionsWithOrgIDs(orgID)
    .map((discussionID: string) => getters.discussionsByID[discussionID]);

const getAssociatedDiscussionID =
  (state: any) => (associatedID: string, clientID: string, supplierID: string) => {
    if (state.discussionLists.byAssociatedID[associatedID] == null) return null;
    const discussionID = state.discussionLists.byAssociatedID[associatedID].find((id: string) => {
      const discussion = state.discussions.byID[id];
      return discussion.clientID === clientID && discussion.supplierID === supplierID;
    });

    return state.discussions.byID[discussionID];
  };
// Get archived discussions ordered by last comment
const archivedDiscussions = (_: any, getters: any) => {
  const obj = getters.discussionsArray.filter((discussion: any) => discussion.archivedAt != null);
  obj.sort((a: any, b: any) => {
    a = new Date(a.comments[a.comments.length - 1].createdAt);
    b = new Date(b.comments[b.comments.length - 1].createdAt);
    return b - a;
  });
  return obj;
};

const filteredDiscussionIDsWithOrg = (state: any) => (filters: any, orgID: string) => {
  if (filters == null || filters?.operands?.length === 0) {
    return state.discussionLists.byOrgID[orgID];
  }

  const filteredDiscussionIDList = state.discussionLists.byOrgID[orgID]?.filter((id: string) => {
    const discussion = state.discussions.byID[id];

    let shouldReturn = filters.operator !== 'or';

    for (const operand of filters.operands) {
      if (filters.operator === 'or') {
        shouldReturn = shouldReturn || discussionMatchesOperand(discussion, operand);
      }
      if (filters.operator === 'and') {
        shouldReturn = shouldReturn && discussionMatchesOperand(discussion, operand);
        // if this discussion doesn't match 1 of the operands
        // it cannot satisfy matching all of them so stop comparing.
        if (shouldReturn === false) {
          break;
        }
      }
    }
    return shouldReturn;
  });

  return filteredDiscussionIDList ?? [];
};

// Get filtered discussions
const filteredDiscussions = (state: any, getters: any) => (filters: any) => {
  // If there are no filters return all discussions
  if (filters == null || filters?.operands?.length === 0) {
    return getters.discussionsArray;
  }

  const filteredDiscussionIDList = state.discussions.allIDs.filter((id: string) => {
    const discussion = state.discussions.byID[id];

    let shouldReturn = filters.operator !== 'or';

    for (const operand of filters.operands) {
      if (filters.operator === 'or') {
        shouldReturn = shouldReturn || discussionMatchesOperand(discussion, operand);
      }
      if (filters.operator === 'and') {
        shouldReturn = shouldReturn && discussionMatchesOperand(discussion, operand);
        // if this discussion doesn't match 1 of the operands
        // it cannot satisfy matching all of them so stop comparing.
        if (shouldReturn === false) {
          break;
        }
      }
    }
    return shouldReturn;
  });

  return filteredDiscussionIDList.map((id: string) => state.discussions.byID[id]);
};

// Gets attachments for discussions (active & archived) with a specified supplier.
// Returns attachments in descending createdAt order.
const attachmentsForDiscussionsWithOrg = (state: any) => (supplierID: string) => {
  const discussionsByOrg = state.discussionLists.bySupplierID[supplierID]?.map(
    (id: string) => state.discussions.byID[id],
  );

  const attachments: any[] = [];

  if (discussionsByOrg != null) {
    discussionsByOrg.forEach((discussion: any) => {
      discussion.comments?.forEach((comment: any) => {
        // grab all the attachments for each discussion if they exist
        if (comment.attachments != null && comment.attachments.length > 0) {
          comment.attachments.forEach((attachment: any) => {
            attachments.push(attachment);
          });
        }
      });
    });
  }

  // sort by descending createdAt order (latest uploaded first)
  attachments.sort((a, b) => {
    a = new Date(a.createdAt);
    b = new Date(b.createdAt);
    return b - a;
  });

  return attachments;
};

const getLogoURLByOrgId = (state: any) => (orgID: string) => state.orgs[orgID].logo;

const getOrgNameByID = (state: any) => (orgID: string) => state.orgs[orgID]?.name;

const getLogoURLByUserID = (state: any, getters: any) => (userID: string) =>
  state.users[userID] != null ? getters.getLogoURLByOrgId(state.users[userID].orgID) : null;

const getVisibleCommentsByDiscussionID = (state: any) => (discussionID: string) => {
  const discussion = discussionsByID(state)[discussionID];
  return discussion.comments.filter((comment: any) => comment.status === CommentStatus.VISIBLE);
};

const getSeenCommentsByDiscussionID = (state: any) => (discussionID: string) => {
  const discussion = discussionsByID(state)[discussionID];

  return discussion?.comments?.filter((comment: any) => comment.status === CommentStatus.SEEN);
};

const commentByID = (state: any) => (commentID: string) => state.comments.byID[commentID];

export const getters = {
  discussionsByID,
  discussionByID,
  commentByID,
  discussionsIDByAssocID,
  discussionsByAssocID,
  filteredDiscussions,
  filteredDiscussionIDsWithOrg,
  discussionIDsWithOrg,
  activeDiscussions,
  discussionsArray,
  archivedDiscussions,
  getAssociatedDiscussionID,
  activeDiscussionsWithOrgIDs,
  activeDiscussionsWithOrg,
  attachmentsForDiscussionsWithOrg,
  userByID,
  userNameByID,
  getLogoURLByOrgId,
  getLogoURLByUserID,
  getVisibleCommentsByDiscussionID,
  getSeenCommentsByDiscussionID,
  getOrgNameByID,
  discussionPreviews,
};
