/* eslint-disable local-rules/no-tanstack */
import type {
  MutationObserverOptions,
  UseQueryOptions,
  DefaultError,
  QueryKey,
  QueryObserverOptions,
} from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import type {
  DeepUnwrapRef,
  MaybeRef,
  MaybeRefDeep,
  MaybeRefOrGetter,
} from 'node_modules/@tanstack/vue-query/build/modern/types';

// A fork of UseQueryOptions with queryKey omitted
export type QueryOptions<TData, TQueryKey extends QueryKey = QueryKey> = MaybeRef<{
  [Property in keyof Omit<
    QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>,
    'queryKey'
  >]: Property extends 'queryFn' | 'select'
    ? QueryObserverOptions<TData, AxiosError, TData, TData, DeepUnwrapRef<TQueryKey>>[Property]
    : Property extends 'enabled'
      ? MaybeRefOrGetter<QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>[Property]>
      : MaybeRefDeep<QueryObserverOptions<TData, AxiosError, TData, TData, TQueryKey>[Property]>;
}>;

export function mergeQueryOptions<TData = unknown, TQueryKey extends QueryKey = QueryKey>(
  userOptions: QueryOptions<TData, TQueryKey> | undefined,
  baseOptions: UseQueryOptions<TData, AxiosError, TData, TData, TQueryKey>,
): UseQueryOptions<TData, AxiosError, TData, TData, TQueryKey> {
  return {
    ...toValue(baseOptions),
    ...toValue(userOptions),
    enabled: () =>
      toValue(toValue(baseOptions)?.enabled) !== false &&
      toValue(toValue(userOptions)?.enabled) !== false,
  };
}

export function mergeMutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  userOptions: MutationObserverOptions<TData, TError, TVariables, TContext> | undefined,
  baseOptions: MutationObserverOptions<TData, TError, TVariables, TContext>,
): MutationObserverOptions<TData, TError, TVariables, TContext> {
  return {
    ...baseOptions,
    ...userOptions,
    async onSuccess(data, variables, context) {
      if (baseOptions.onSuccess) await baseOptions.onSuccess(data, variables, context);
      if (userOptions?.onSuccess) await userOptions.onSuccess(data, variables, context);
    },
    async onError(error, variables, context) {
      if (baseOptions.onError) await baseOptions.onError(error, variables, context);
      if (userOptions?.onError) await userOptions.onError(error, variables, context);
    },
  };
}

/**
 * A function to pass an arg and `enabled` to a TanStack Query composable when
 * and only when `arg` is defined.
 *
 * Previously, we had a pattern like this:
 *
 * ```
 * const query = useComposable(() => myArg!, {
 *   enabled: () => !!myArg,
 * });
 * ```
 *
 * This meant we had a lot of non-null assertion operators all over the place,
 * and we could forget to pass the `enabled` option. Now we can write this:
 *
 * ```
 * const query = whenNonNull(
 *   () => myArg,
 *   (enabled, myArg) => useComposable(myArg, { enabled }),
 * );
 * ```
 */
export function whenNonNull<TArg, TReturnType>(
  arg: MaybeRefOrGetter<TArg>,
  // the NonNullable is technically a lie, but if `enabled` is respected then
  // it won't be called when `arg` is undefined. allows us to consolidate all
  // the non-null operators we had previously into a single place
  useComposable: (enabled: () => boolean, arg: () => NonNullable<TArg>) => TReturnType,
): TReturnType {
  return useComposable(
    () => !!toValue(arg),
    () => toValue(arg)!,
  );
}

/**
 * Same as whenNonNull, but with two arguments.
 *
 * Having an array of generics in TypeScript is very complicated / impossible,
 * so we duplicate the function instead.
 */
export function whenBothNonNull<TArg1, TArg2, TReturnType>(
  arg1: MaybeRefOrGetter<TArg1>,
  arg2: MaybeRefOrGetter<TArg2>,
  useComposable: (
    enabled: () => boolean,
    arg1: () => NonNullable<TArg1>,
    arg2: () => NonNullable<TArg2>,
  ) => TReturnType,
): TReturnType {
  return useComposable(
    () => !!toValue(arg1) && !!toValue(arg2),
    () => toValue(arg1)!,
    () => toValue(arg2)!,
  );
}
