import { request } from '@/api/request';
import type { Role } from '@/types/models/roles';
import type { Answer } from '@/modules/profile/api/client';

// Called OrganisationPartial on the backend. This is exclusively used in Zeus,
// and contains very little information of each org.
export type ZeusOrganisationLite = {
  id: string;
  name: string;
  logoUrl: string;
  isClient: boolean;
  isSupplier: boolean;
  isFederated: boolean;
  createdAt: string;
  deleted: boolean;
};

// OrgList
const getOrganisationList = () => request<ZeusOrganisationLite[]>('GET', '/zeus/organisations');

// OrgPage
const getOrganisation = (orgID: string) => request('GET', `/zeus/organisations/${orgID}`);

const getOrgRoles = (orgID: string) => request<Role[]>('GET', `/zeus/organisations/${orgID}/roles`);

export type OrgProfile = {
  id: string;
  orgID: string;
  type: string;
  createdAt: string;
  createdBy: string;
  modifiedAt: string;
  modifiedBy: string;
  deletedAt: string | null;
  deletedBy: string | null;
};

const deleteOrganisation = (orgID: any) => request('DELETE', `/god/orgs/${orgID}`);
const confirmOrganisation = (orgID: any) => request('POST', `/zeus/organisations/${orgID}/confirm`);
const setOrgSuspended = (orgID: any, suspended: any) =>
  request('POST', `/god/orgs/${orgID}/suspended`, { suspended });
const setOrgHidden = (orgID: any, hidden: any) =>
  request('POST', `/god/orgs/${orgID}/hidden`, { hidden });
const setOrgPaid = (orgID: any, paid: any) => request('PUT', `/god/orgs/${orgID}/paid`, { paid });
const addUser = (userData: any) => request('POST', '/zeus/users', userData);
const deleteUser = (userID: any, deletedReason: any) =>
  request('DELETE', `/zeus/users/${userID}`, { deletedReason });
const getOrgAnswers = (orgID: string) => request<Answer[]>('GET', `/god/orgs/${orgID}/answers`);
const setAssessmentRemindersPause = (orgID: any, assessmentRemindersPause: any) =>
  request('POST', `/god/orgs/${orgID}/assessmentreminders/pause`, { assessmentRemindersPause });
const toggleOrgClientRestricted = (orgID: any) =>
  request('POST', `/zeus/organisations/${orgID}/toggleClientRestrict`);
const toggleMonitoringAssetStatus = (assetID: any) =>
  request('PUT', `/zeus/externalmonitoring/assets/${assetID}/toggleStatus`);

// UserList
const searchUsers = (query: any) => request('GET', '/god/users/search', undefined, { query });

// UserPage
const getUserData = (userID: string) => request('GET', `/god/users/${userID}`);
const confirmUser = (userID: string) => request('POST', `/god/users/${userID}/confirm`);
const editUserData = (userID: string, data: any) => request('PATCH', `/god/users/${userID}`, data);
const generateNewPassword = (userID: string) => request('POST', `/god/users/${userID}/resetPass`);
const resendTempPassword = (userID: string) =>
  request('POST', `/god/users/${userID}/resendTempPass`);
const setUserMFAtoSMS = (userID: string) => request('POST', `/zeus/users/${userID}/setMFAToSMS`);
const disableUserMFAPreference = (userID: string) =>
  request('POST', `/zeus/users/${userID}/disableMFA`);
const resetUserMFAPreference = (userID: string) =>
  request('POST', `/zeus/users/${userID}/resetMFA`);
const sendInvites = (senderUserID: string, invites: any) =>
  request('POST', '/god/invites', { senderUserID, invites });
const createBulkUnclaimedSuppliers = (senderUserID: any, orgs: any) =>
  request('POST', '/zeus/suppliers/new', { senderUserID, orgs });

// Invite Reminders
const getPendingInvites = () => request('GET', `/zeus/pendinginvites`);
const setPendingInviteReminderPauseStatus = (inviteID: any, paused: any) =>
  request('POST', `/zeus/pendinginvites/${inviteID}/pausereminders`, {
    paused,
  });

// Federated
const createFederatedOrg = ({ name, website, country, industry }: any) =>
  request('POST', `/zeus/federatedorganisations/create`, { name, website, country, industry });

const getAllFederatedOrgs = () => request('GET', '/zeus/federatedorganisations');

export const live = {
  getOrganisationList,
  searchUsers,
  getUserData,
  confirmUser,
  editUserData,
  generateNewPassword,
  resendTempPassword,
  setUserMFAtoSMS,
  sendInvites,
  getOrganisation,
  deleteOrganisation,
  setOrgSuspended,
  setOrgHidden,
  setOrgPaid,
  addUser,
  deleteUser,
  getOrgAnswers,
  getOrgRoles,
  setAssessmentRemindersPause,
  getPendingInvites,
  setPendingInviteReminderPauseStatus,
  createFederatedOrg,
  getAllFederatedOrgs,
  confirmOrganisation,
  createBulkUnclaimedSuppliers,
  disableUserMFAPreference,
  resetUserMFAPreference,
  toggleOrgClientRestricted,
  toggleMonitoringAssetStatus,
};

const api = { ...live };
export default api;

export const replaceAPI = (newApi: any) => {
  Object.keys(newApi).forEach((i) => {
    (api as any)[i] = newApi[i];
  });
};
