import type { Getter } from 'vuex';
import { ReassessmentTriggerType } from '@/types/models/reassessment';
import type { Control } from '@/types/models/control';
import type { Domain } from '@/types/models/domain';
import type { State as RootState } from '@/store/useStore';
import type { State } from './mutations';

// Get object of domainID mapped to number of answers
const numAnswersByDomain: Getter<State, RootState> = (state, _getters, rootState, rootGetters) => {
  const controlsByDomain = rootGetters['framework/controlsByDomain'] as Record<string, string[]>;
  const frameworkRequiredControlIDs = rootState.framework.frameworkRequiredControlIDs;
  return Object.fromEntries(
    Object.entries(controlsByDomain).map(([domainID, controls]) => [
      domainID,
      controls.reduce((i, c) => {
        if (!frameworkRequiredControlIDs.includes(c)) return i;
        const a = state.controlAnswers[c]
          ? state.controlAnswers[c].answer || state.controlAnswers[c].notApplicable
          : null;
        return a ? i + 1 : i;
      }, 0),
    ]),
  );
};

// Get percentage of controls that have been answered, by domain
// Ignores deprecated controls in percentage
const percentageDomainAnswered: Getter<State, RootState> =
  (_state, getters, rootState, rootGetters) => (domainID: string) => {
    const controlsByDomain = rootGetters['framework/controlsByDomain'] as Record<string, string[]>;
    const frameworkRequiredControlIDs = rootState.framework.frameworkRequiredControlIDs;
    const controls =
      controlsByDomain[domainID]?.filter((control) => frameworkRequiredControlIDs.includes(control))
        .length || 0;
    const answers = getters.numAnswersByDomain[domainID];
    return Math.floor((answers / controls) * 100);
  };

const totalScopingQuestions: Getter<State, RootState> = (
  _state,
  _getters,
  _rootState,
  rootGetters,
) => {
  // Get sortedDomains
  const sortedDomains = rootGetters['framework/sortedDomains'] as Domain[];

  // Filter domains by those that have a scoping question
  const scopingQuestions = sortedDomains.filter((domain) => domain.scopingQuestion);
  const totalScopingQuestions = scopingQuestions.length;

  return totalScopingQuestions;
};

const totalScopingAnswered: Getter<State, RootState> = (
  state,
  _getters,
  _rootState,
  rootGetters,
) => {
  // Get sortedDomains
  const sortedDomains = rootGetters['framework/sortedDomains'] as Domain[];
  const scopingQuestions = sortedDomains.filter((domain) => domain.scopingQuestion);

  const scopingAnswered = scopingQuestions.reduce<Record<string, boolean>>((res, question) => {
    if (state.scopingAnswers[question.domainID]) {
      res[question.domainID] = true;
    }
    return res;
  }, {});
  const totalScopingAnswered = Object.keys(scopingAnswered).length;
  return totalScopingAnswered;
};

// Get the percentage of scoping that have been answered
const percentageScopingAnswered: Getter<State, RootState> = (_state, getters) => () =>
  Math.floor((getters.totalScopingAnswered / getters.totalScopingQuestions) * 100);

// Use to lookup whether a domainID is out of scope.
// Returns map of domainID to true if out of scope, or false if in scope.
// If domain does not have a scoping answer, it is in scope.
const outOfScope: Getter<State, RootState> = (state, _getters, rootState) =>
  Object.fromEntries(
    rootState.framework.domains.map((domain) => [
      domain.domainID,
      state.scopingAnswers?.[domain.domainID]?.answer === false,
    ]),
  );

const isScopingComplete: Getter<State, RootState> = (state) =>
  state.status ? state.status.scopingComplete : false;

const isAssessmentComplete: Getter<State, RootState> = (state) =>
  state.status ? state.status.initialAssessmentComplete : false;

const isReassessing: Getter<State, RootState> = (state) =>
  state.reassessmentStatus && state.reassessmentStatus.status === 'reassessment_inprogress';

const isReassessmentScheduled: Getter<State, RootState> = (state) =>
  state.reassessmentStatus &&
  state.reassessmentStatus.triggerType === ReassessmentTriggerType.ScheduledReassessment;

// Get sorted deprecated controls that have been answered.
// Returns a list of controlIDs.
// TODO: (30 minutes) what is the difference between this and the profile store
// getter of the same name?
const sortedControlAnswersDeprecated: Getter<State, RootState> = (
  state,
  _getters,
  _rootState,
  rootGetters,
) => {
  const controlAnswersIDs = Object.keys(state.controlAnswers);
  const sortedControlsDeprecated = (
    rootGetters['framework/sortedControlsDeprecated'] as Control[]
  ).reduce((res, controlObj) => {
    res.push(controlObj.controlID);
    return res;
  }, [] as string[]);
  return sortedControlsDeprecated.filter((value) => controlAnswersIDs.includes(value));
};

// Moved to composable but still kept because another getter uses it still.
const controlsRequiringAttention: Getter<State, RootState> = (
  state,
  getters,
  _rootState,
  rootGetters,
) => {
  const sortedControls = rootGetters['framework/sortedControls'] as Control[];
  const answers = state.controlAnswers;
  const outOfScope = getters.outOfScope;
  const isReassessing = getters.isReassessing;
  const domainsByID = rootGetters['framework/domainsByID'] as Record<string, Domain>;
  const scopingAnswers = state.scopingAnswers;

  return sortedControls
    .filter((control) => {
      // Ignore deprecated and out of scope
      if (control.deprecated || outOfScope[control.domainID]) return false;

      // Add controls that changed meaning
      const answer = answers[control.controlID];
      if (answer && !answer.notApplicable && answer.unconfirmedControlUpdate) {
        return true;
      }

      // If reassessing then also add unanswered applicable controls (whose scoping question was answered)
      if (isReassessing) {
        if (!domainsByID[control.domainID]?.scopingQuestion || scopingAnswers[control.domainID]) {
          if (!answer || (!answer.answer && !answer.notApplicable)) {
            return true;
          }
        }
      }

      return false;
    })
    .map((control) => control.controlID);
};

// Get sorted domains that have controls or scoping questions requiring attention.
// When reassessing it also returns domains that have unconfirmed controls.
// When not reassessing it also returns domains that have unanswered controls.
// Returns a list of domain objects.
const sortedIncompleteDomains: Getter<State, RootState> = (
  state,
  getters,
  _rootState,
  rootGetters,
) => {
  const sortedDomains = rootGetters['framework/sortedDomains'] as Domain[];
  const domainsByID = rootGetters['framework/domainsByID'] as Record<string, Domain>;
  const outOfScope = getters.outOfScope;

  // get domains from controls requiring attention
  const domainsFromControls = (getters.controlsRequiringAttention as string[]).map((controlID) => {
    const control = rootGetters['framework/controlsByID'][controlID];
    return domainsByID[control.domainID]!;
  });

  // get domains from scoping questions requiring attention
  const domainsFromScopingQuestions = sortedDomains.filter(
    (domain) =>
      !domain.deprecated && domain.scopingQuestion && !state.scopingAnswers[domain.domainID],
  );

  // get non 100% answered domains
  const nonCompleteDomains = sortedDomains.filter(
    (domain) =>
      !domain.deprecated &&
      !outOfScope[domain.domainID] &&
      getters.percentageDomainAnswered(domain.domainID) < 100,
  );

  // combine and remove duplicates based on if we are reassessing or not
  const filteredDomains: Domain[] = [
    ...new Set([...domainsFromControls, ...domainsFromScopingQuestions, ...nonCompleteDomains]),
  ];

  // Sort filtered domains by letter
  filteredDomains.sort((a, b) => {
    if (a.letter < b.letter) {
      return -1;
    }
    if (a.letter > b.letter) {
      return 1;
    }

    return 0;
  });

  return filteredDomains;
};

export const getters = {
  numAnswersByDomain,
  percentageDomainAnswered,
  percentageScopingAnswered,
  outOfScope,
  isScopingComplete,
  isAssessmentComplete,
  sortedControlAnswersDeprecated,
  isReassessing,
  isReassessmentScheduled,
  totalScopingQuestions,
  totalScopingAnswered,
  controlsRequiringAttention,
  sortedIncompleteDomains,
};
