import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';

export const actionCentreClientRoutes = [
  {
    path: 'action-centre',
    name: 'client-action-centre',
    redirect: { name: 'client-action-centre-connection' },
    children: [
      {
        path: 'connection',
        name: 'client-action-centre-connection',
        component: () => import('./ActionCentre.vue'),
        props: { tab: 'connection' },
      },
      {
        path: 'remediation',
        name: 'client-action-centre-remediation',
        component: () => import('./ActionCentre.vue'),
        props: { tab: 'remediation' },
      },
      {
        path: 'invite',
        name: 'client-action-centre-invite',
        component: () => import('./ActionCentre.vue'),
        props: { tab: 'invite' },
        meta: {
          permissions: [Permission.ClientsInvitesView, Permission.ClientsInvitesManage],
        },
      },
    ],
  },
] satisfies RouteRecordRaw[];

export const actionCentreSupplierRoutes = [
  {
    path: 'action-centre',
    name: 'supplier-action-centre',
    redirect: { name: 'supplier-action-centre-connection' },
    children: [
      {
        path: 'connection',
        name: 'supplier-action-centre-connection',
        component: () => import('./ActionCentre.vue'),
        props: { tab: 'connection' },
      },
      {
        path: 'remediation',
        name: 'supplier-action-centre-remediation',
        component: () => import('./ActionCentre.vue'),
        props: { tab: 'remediation' },
      },
    ],
  },
] satisfies RouteRecordRaw[];
