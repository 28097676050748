export default {
  en: {
    title: 'Clients',
    pageTitle: 'Clients - All Clients',
    pageHeader: 'Clients',
    headerViewName: 'All Clients',
    entityName: 'Client',
    dataTableColumns: {
      client: 'Client Name',
      compliance: 'Compliance',
      approvalStatus: 'Review Status',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'PII',
      labels: 'Labels',
      products: 'Products',
      assignedUsers: 'Assigned',
      policiesApplied: 'Policies Applied',
      assessmentComplete: 'Assessment Status',
      dateAdded: 'Date Added',
      companyNumber: 'Company Number',
      country: 'Country',
      managed: 'RL Managed',
      initialAssessmentProgress: 'Initial Assessment Progress',
      lastUpdatedAt: 'Last Answer Updated',
      sector: 'Sector',
      website: 'Website',
      exemptionsApplied: 'Exemptions Applied',
      nonComplianceApplied: 'Non-Compliance Applied',
      remediationRequests: 'Remediation Requests',
      remediationResponses: 'Remediation Responses',
      evidence: 'Evidence',
      activeDiscussions: 'Active Discussions',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      approvalExpiresAt: 'Review Expiry',
      reassessmentStatus: 'Re-Assessment Status',
      reassessmentPreviousCompletedAt: 'Last Assessment',
      initialAssessmentCompleteAt: 'Initial Assessment Complete',
      reassessmentNextDueAt: 'Next Re-Assessment Due',
      latestAssessmentProgressPercentage: 'Assessment Progress',
    },
    dataTable: {
      managedLabel: 'RL Managed',
      managedDescription: 'This profile has been created and is managed by Risk Ledger.',
      incomplete: 'Incomplete',
      filterResultsClients: '{count} Clients | {count} Client | {count} Clients',
      filterResultsOutOf: '{0} out of {1}',
      deletedUser: 'Deleted User',
    },
    filterAttributes: {
      searchPlaceholder: 'Search clients...',
      name: 'Name',
      companyNumber: 'Company Number',
      website: 'Website',
      country: 'Country',
      product: 'Product',
      lastActiveAt: 'Last Answer Updated',
      initialAssessmentProgress: 'Initial Assessment Progress',
      initialAssessmentComplete: 'Assessment Status',
      initialAssessmentCompleteTrue: 'is Complete',
      initialAssessmentCompleteFalse: 'is not Complete',
      initialAssessmentCompleteAt: 'Initial Assessment Completion Date',
      managed: 'RL Managed',
      managedTrue: 'is Managed',
      managedFalse: 'is not Managed',
      percentageCompliance: 'Compliance',
      criticality: 'Criticality',
      confidentiality: 'Confidentiality',
      holdsPII: 'Holds PII',
      approvalStatus: 'Review Status',
      approved: 'Review Complete',
      rejected: 'Rejected',
      pending_biz: 'Pending business',
      approvedExpiry: 'Approval Expired',
      inreview: 'In Review',
      unapproved: 'Unapproved',
      awaitingReview: 'Awaiting Review',
      approvalModifiedAt: 'Approval Last Modified',
      approvalExpiresAt: 'Approval Expiry',
      numPoliciesApplied: 'Number of Policies Applied',
      createdAt: 'Date Added',
      labels: 'Labels',
      sectorID: 'Sector',
      assignedUsers: 'Assigned Users',
      numActiveDiscussions: 'Number of Active Discussions',
      numExemptionsApplied: 'Number of Exemptions Applied',
      numNonComplianceApplied: 'Number of Non Compliances Applied',
      numRemediationRequests: 'Number of Remediation Requests',
      numRemediationResponses: 'Number of Remediation Responses',
      numEvidence: 'Number of Evidence',
      assignedToMe: 'Assigned to Me',
      assignedToMeTrue: 'is Assigned to Me',
      assignedToMeFalse: 'is not Assigned to Me',
      invitedAt: 'Date Invited',
      invitedBy: 'Invite Sender',
      reassessmentStatus: 'Re-Assessment Status',
      reassessmentStatusIdle: 'Complete',
      reassessmentStatusDue: 'Due',
      reassessmentStatusOverdue: 'Overdue',
      reassessmentStatusInProgress: 'In progress',
      reassessmentStatusInitialInProgress: 'Initial assessment in progress',
      reassessmentPreviousCompletedAt: 'Last Assessment',
      reassessmentNextDueAt: 'Next Re-Assessment Due',
      latestAssessmentProgressPercentage: 'Assessment Progress',
    },
    saveModal: {
      title: 'Save a new view',
      save: 'Save View',
      cancel: 'Cancel',
      description:
        'Give your view a name below. Once saved, it will appear in the sidebar for you and your colleagues.',
      placeholder: 'Name of view...',
    },
    deleteModal: {
      title: `Delete view '{0}'?`,
      description: `Please confirm if you would like to remove the '{0}' view. It will no longer appear in the sidebar for you and your colleagues.`,
      delete: 'Delete View',
      cancel: 'Cancel',
      failed: 'Deleting the view failed.',
    },
    placeholders: {
      emptyTitle: 'Clients',
      emptyDescription: 'No connections could be found',
      errorTitle: 'Clients',
      errorDescription: 'Failed fetching connections',
    },
    export: 'Export',
    exportError: 'There was an error attempting to export the current view',
  },
};
