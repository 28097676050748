import { InfoFindingCode } from './types';

export default {
  en: {
    [InfoFindingCode.INFO_SPF_HARD_FAIL]: {
      finding: "Uses '-all' instead of recommended '~all'",
      explanation:
        'The domain uses a strict rejection policy ("-all") for unauthorised senders. While secure, this may block legitimate emails if the SPF record is not carefully maintained. The domains related are:',
    },
    [InfoFindingCode.INFO_CSP_DEPENDENCIES]: {
      finding: 'CSP has {n} dependency | CSP has {n} dependencies',
      explanation:
        'The detected Content Security Policy (CSP) includes various external dependencies. These define which resources the website allows for scripts, styles, and other content, helping to mitigate security risks like XSS attacks. Review the listed dependencies to ensure they align with your security policies.',
    },
    [InfoFindingCode.INFO_CHAIN_LIMIT_EXCEEDED]: {
      finding: 'Redirect limit exceeded',
      explanation:
        'The resource accessed has too many redirects, exceeding the allowed limit. This may be due to a misconfiguration or an infinite redirect loop.',
      redirectionChainTitle: 'The redirected domains are:',
    },
    [InfoFindingCode.INFO_CHAIN_TLD_CHANGED]: {
      finding: 'Top level domain changed in redirection',
      explanation:
        'The resource has been redirected to a different top level domain. . This may indicate a domain migration, misconfiguration, or a potential security concern.',
      redirectionChainTitle: 'The redirected domains are:',
    },
    [InfoFindingCode.INFO_HTTP_TIMEOUT]: {
      finding: 'No timely response during HTTP request',
      explanation:
        'The server took too long to respond to the request. This may be due to high server load, network issues, or the server being down.',
    },
    [InfoFindingCode.INFO_DNS_TIMEOUT]: {
      finding: 'No timely response during DNS lookup',
      explanation:
        'The DNS resolver did not respond within the expected time. This could be caused by network issues, an unresponsive DNS server, or misconfigured DNS settings.',
    },
    [InfoFindingCode.INFO_HOST_NOT_FOUND]: {
      finding: 'Domain does not exist',
      explanation:
        'The domain that is scanned does not exist in the DNS system. This could be due to a typo, an unregistered domain, or a DNS configuration issue.',
    },
  },
};
