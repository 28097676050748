import type { RouteRecordRaw } from 'vue-router';
import Permission from '@/composables/permissions/permissions';
import { requireDisabledFlag } from '@/routes/guards/flag';
import { FeatureFlag } from '@/composables/feature-flags';

export default [
  {
    path: 'network',
    redirect: { name: 'client-network' },
    meta: {
      permissions: [Permission.ClientsNetworkView],
    },
    beforeEnter: [requireDisabledFlag(FeatureFlag.DisableNetworkVis)],
  },
  {
    path: 'network/visualisation',
    name: 'client-network',
    component: () => import('./ClientNetwork.vue'),
    meta: {
      permissions: [Permission.ClientsNetworkView],
    },
    beforeEnter: [requireDisabledFlag(FeatureFlag.DisableNetworkVis)],
  },
  {
    path: 'network/table',
    name: 'client-table',
    component: () => import('./ClientNetworkTablePage.vue'),
    meta: {
      permissions: [Permission.ClientsNetworkView],
    },
    beforeEnter: [requireDisabledFlag(FeatureFlag.DisableNetworkVis)],
  },
  {
    path: 'network/community/:communityId?/visualisation',
    name: 'community-network',
    component: () => import('./CommunityNetwork.vue'),
    meta: {
      permissions: [Permission.ClientsNetworkView],
    },
    beforeEnter: [requireDisabledFlag(FeatureFlag.DisableNetworkVis)],
    props: true,
  },
] satisfies RouteRecordRaw[];
