import type { Action } from 'vuex';
import api from '../api';
import type { State } from './mutations';

const getAssessmentStatus: Action<State, any> = async ({ commit, dispatch }) => {
  const res = await api.getAssessmentStatus();
  commit('SET_ASSESSMENT_STATUS', res.data);
  await dispatch('getReassessmentStatus');
};

const changeScopingAnswer: Action<State, any> = async ({ commit }, { domainID, answer, notes }) => {
  const res = await api.changeScopingAnswer(domainID, answer, notes);
  commit('CHANGE_SCOPING_ANSWER', res.data);
};

const getScopingAnswers: Action<State, any> = async ({ commit }) => {
  const res = await api.getScopingAnswers();
  commit('SET_SCOPING_ANSWERS', res.data);
};

const getControlBookmarks: Action<State, any> = async ({ commit }) => {
  const res = await api.getControlBookmarks();
  commit('SET_CONTROL_BOOKMARKS', res.data);
};

const bookmarkControl: Action<State, any> = async ({ commit }, { controlID }) => {
  const res = await api.bookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const unbookmarkControl: Action<State, any> = async ({ commit }, { controlID }) => {
  const res = await api.unbookmarkControl(controlID);
  commit('SET_CONTROL_BOOKMARK', res.data);
};

const confirmControlUpdate: Action<State, any> = async (_store, controlID) => {
  await api.confirmControlUpdate(controlID);
};

const getReassessmentStatus: Action<State, any> = async ({ commit }) => {
  const res = await api.getReassessmentStatus();
  commit('SET_REASSESSMENT_STATUS', res.data);
};

const submitAssessment: Action<State, any> = async ({ commit }) => {
  const res = await api.submitAssessment();
  commit('SET_ASSESSMENT_STATUS', res.data);
};

const startReassessment: Action<State, any> = async ({ commit }) => {
  const res = await api.startReassessment();
  commit('START_REASSESSMENT', res.data);
};

export const actions = {
  getAssessmentStatus,
  changeScopingAnswer,
  getScopingAnswers,
  submitAssessment,
  startReassessment,
  getReassessmentStatus,
  confirmControlUpdate,
  getControlBookmarks,
  bookmarkControl,
  unbookmarkControl,
};
