import type { NavigationGuard, RouteRecordRedirectOption } from 'vue-router';
import type { FeatureFlag } from '@/composables/feature-flags';
import useFeatureFlags from '@/composables/feature-flags';

export function requireFlag(flag: FeatureFlag) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    if (!ff(flag)) {
      next('/');
      return;
    }
    next();
  }) satisfies NavigationGuard;
}

export function requireDisabledFlag(flag: FeatureFlag) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    if (ff(flag)) {
      next('/');
      return;
    }
    next();
  }) satisfies NavigationGuard;
}

export function requireAnyFlag(...flags: FeatureFlag[]) {
  return ((_to, _from, next) => {
    const { ff } = useFeatureFlags();

    for (const flag of flags) {
      if (ff(flag)) {
        next();
        return;
      }
    }

    next('/');
  }) satisfies NavigationGuard;
}

interface RedirectOptions {
  to: string;
  defaultTo: string;
}

/**
 * Redirects to a different route based on the feature flag.
 * @param flag - The feature flag to check
 * @param options.to - The route to redirect to if the feature flag is enabled
 * @param options.defaultTo - The route to redirect to if the feature flag is not enabled
 * @returns A route record redirect option.
 */
export function redirectWithFlag(
  flag: FeatureFlag,
  options: RedirectOptions,
): RouteRecordRedirectOption {
  return () => {
    const { ff } = useFeatureFlags();
    return { name: ff(flag) ? options.to : options.defaultTo };
  };
}
