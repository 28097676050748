import type { InfractionText } from './types';

export const dnssecInfractions: Record<string, InfractionText> = {
  DNS_NO_DNSSEC: {
    infraction: 'Domain has no DNSSEC records configured',
    explanation:
      "DNSSEC cryptographically signs the domain's DNS records, allowing resolvers to verify their authenticity. Without DNSSEC, the domain is vulnerable to DNS spoofing and cache poisoning attacks.",
    risk: 'Domain vulnerable to DNS spoofing and cache poisoning attacks',
  },
  DNS_INVALID_DNSSEC: {
    infraction: 'DNSSEC signatures are present but invalid or expired',
    explanation:
      "The domain's DNSSEC signatures are present but invalid or expired. This prevents resolvers from verifying the authenticity of the domain's DNS records.",
    risk: "Resolvers cannot verify the authenticity of the domain's DNS records, leaving you vulnerable to DNS spoofing and cache poisoning attacks",
  },
  DNS_MISSING_DS: {
    infraction: 'No Delegation Signer (DS) records in parent zone',
    explanation:
      "The Delegation Signer is a record in the parent zone that links the domain's DNSSEC keys to the parent zone. These are used to build a verified chain of authenticity between a domain and a trusted DNS zone authority.",
    risk: "The DNSSEC chain of trust is broken. Resolvers cannot validate the domain's DNSSEC signatures, leaving you vulnerable to DNS spoofing and cache poisoning attacks",
  },
  DNS_EXPIRING_SOON: {
    infraction: 'Domain registration will expire within 30 days',
    explanation:
      'The domain registration is set to expire within 30 days. If the domain expires, all services associated with it, including email and websites, will stop working.',
    risk: 'Domain services will stop working if the domain expires',
  },
  DNS_ZONE_TRANSFER: {
    infraction: 'DNS server allows zone transfers to unauthorized hosts',
    explanation:
      "The DNS server allows unauthorized hosts to request and download the domain's zone file. This exposes sensitive DNS records to attackers.",
    risk: 'Sensitive DNS records, such as email server addresses and subdomains may be used in targeted attacks',
  },
  DNS_NO_PTR: {
    infraction: 'No PTR records configured for domain IP addresses',
    explanation:
      'PTR records map IP addresses to domain names, allowing reverse DNS lookups. Without PTR records, email servers may reject or mark emails as spam.',
    risk: 'Email servers may reject or mark emails as spam, reduced domain reputation',
  },
  DNS_WEAK_ALGO: {
    infraction: 'Using deprecated or weak cryptographic algorithms for DNSSEC',
    explanation:
      'The cryptographic algorithms used to sign your DNSSEC records are deprecated or considered weak.',
    risk: 'Vulnerable to cryptographic attacks, such as brute force decryption',
  },
  DNS_NS_ISSUES: {
    infraction: 'Inconsistent or problematic nameserver configuration',
    explanation:
      'Domain nameservers are the authoritative servers that store DNS records. Inconsistent nameserver configurations can cause DNS resolution issues.',
    risk: 'Potential DNS resolution issues, in some cases, domain may become unreachable',
  },
  DNS_SOA_ISSUES: {
    infraction: 'Missing or invalid Start of Authority record parameters',
    explanation:
      'The Start of Authority (SOA) record indicates who is responsible for that domain and how often DNS records should be refreshed and transferred between DNS zones.',
    risk: 'Issues with DNS Zone transferral may lead to stale or incorrect DNS records, leading to resolution issues or even service disruption',
  },
  DNS_NO_IPV6: {
    infraction: 'Nameserver has no IPv6 address configured',
    explanation:
      'IPv6 is the next-generation Internet Protocol that provides a larger address space than IPv4.',
    risk: 'Without an IPv6 address, the domain may not be reachable by IPv6-only clients',
  },
  DNS_NO_LOCK: {
    infraction: 'Domain lacks registry lock protection',
    explanation:
      'Registry lock protection prevents unauthorized changes to the domain registration, such as transfers or DNS modifications.',
    risk: 'Domain registration may be hijacked or transferred without authorization',
  },
  DNS_NO_GLUE: {
    infraction: 'No glue records for nameservers in the same domain',
    explanation:
      'Glue records are A or AAAA records that provide IP addresses for domain nameservers.',
    risk: 'DNS resolution may be slower or even fail in some case',
  },
  DNS_RECURSIVE: {
    infraction: 'DNS servers allow recursive queries from any source',
    explanation:
      'Recursive DNS queries are requests made by a resolver to a DNS server that resolves the query by querying other DNS servers.',
    risk: 'Attackers can use your DNS server to amplify DDoS attacks',
  },
  DNS_KEY_EXPIRING: {
    infraction: 'DNSSEC keys are approaching expiration',
    explanation:
      "DNSSEC keys are used to sign DNS records and validate their authenticity. If the keys expire, resolvers cannot verify the domain's DNS records.",
    risk: 'Risk of DNSSEC validation failures if not resolved',
  },
  DNS_MALFORMED_WHOIS: {
    infraction: 'Malformed or missing WHOIS records',
    explanation:
      'WHOIS records contain information about the domain registrant, registrar, and registration dates. Missing WHOIS records may mean that domain registration is expired or improperly registered.',
    risk: 'Risk of deregistration which will cause a total service disruption',
  },
};
