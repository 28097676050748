import type { InfractionText } from './types';

export const spfInfractions: Record<string, InfractionText> = {
  SPF_MISSING: {
    infraction: 'The domain has no SPF record configured',
    explanation:
      'A Sender Policy Framework (SPF) record tells email servers which systems are allowed to send email from the domain. Without an SPF record, any server can impersonate the domain by sending unauthorised emails.',
    risk: 'Allows email spoofing, phishing attacks, and unauthorised sending of emails from your domain. Recipients have no way to verify legitimate email sources',
  },
  SPF_EXPLICIT_ALL: {
    infraction: "SPF record contains '+all' or 'all' suffix",
    explanation:
      'The domain\'s SPF record contains "+all" or "?all", which permits any server to send email as the domain. This removes all protection against email spoofing.',
    risk: 'Explicitly authorises any IP address to send email on behalf of your domain, completely defeating the purpose of SPF protection',
  },
  SPF_NEUTRAL_ALL: {
    infraction: "Uses '?all' suffix or lacks an 'all' directive",
    explanation:
      'The domain uses a neutral SPF policy ("?all"), which only monitors unauthorised senders without blocking them. This provides minimal protection against email spoofing.',
    risk: 'Most mail servers will accept emails from any source, providing minimal protection against spoofing and phishing attempts',
  },
  SPF_MULTIPLE_RECORDS: {
    infraction: 'Domain has more than one SPF record configured',
    explanation:
      'The domain has more than one SPF record. Email servers can only process one record, making email validation unpredictable and potentially blocking legitimate messages.',
    risk: 'Creates unpredictable behavior as mail servers randomly choose which record to use, potentially allowing unauthorised senders',
  },
  SPF_PTR_RECORD: {
    infraction: 'SPF record uses PTR mechanisms for validation',
    explanation:
      'The SPF record relies on PTR records for validation. This method is unreliable and can be manipulated by attackers to bypass email authentication.',
    risk: 'Introduces security vulnerabilities through DNS-based attacks and provides unreliable sender validation',
  },
  SPF_TOO_MANY_LOOKUPS: {
    infraction: 'SPF record evaluation requires more than 10 DNS lookups',
    explanation:
      'The SPF record needs more than 10 DNS lookups to validate senders. This exceeds the standard limit and may cause email validation to fail.',
    risk: 'May cause mail servers to skip SPF validation due to lookup limits, potentially allowing unauthorised senders',
  },
  SPF_INVALID_RECORD: {
    infraction: 'The root SPF record is malformed or contains syntax errors',
    explanation:
      'The SPF record contains syntax errors that prevent email servers from properly validating senders. This can cause legitimate emails to fail or allow unauthorised senders.',
    risk: 'Mail servers may ignore or incorrectly interpret the SPF record, effectively providing no protection against email spoofing',
  },
  SPF_INVALID_SUBRECORD: {
    infraction: 'SPF record includes invalid or non-existent secondary records',
    explanation:
      "The SPF record references other domains' SPF records that don't exist. This creates security gaps and may cause email validation errors.",
    risk: 'Parts of your authorised sender list may be missing or incorrect, creating gaps in email security',
  },
  SPF_DEPRECATED_TYPE: {
    infraction: 'Uses deprecated SPF-type record instead of TXT record',
    explanation:
      'The domain uses an outdated SPF record type. While still functional, this may cause compatibility issues with some email systems.',
    risk: 'May cause compatibility issues with mail servers and future SPF implementations',
  },
  SPF_IP_AFTER_ALL: {
    infraction: "IP addresses or other mechanisms listed after the 'all' directive",
    explanation:
      'The SPF record contains rules that appear after the "all" directive. These rules will never be checked, creating potential security gaps.',
    risk: 'Configuration error that causes listed mechanisms to be ignored, potentially excluding legitimate senders',
  },
  SPF_HARD_FAIL: {
    infraction: "Uses '-all' instead of recommended '~all'",
    explanation:
      'The domain uses a strict rejection policy ("-all") for unauthorised senders. While secure, this may block legitimate emails if the SPF record is not carefully maintained.',
    risk: 'May cause legitimate emails to be rejected outright rather than marked as suspicious, potentially disrupting email delivery',
  },
  // Deprecated
  SPF_INVALID: {
    infraction: 'The root SPF record is malformed or contains syntax errors',
    explanation:
      'The SPF record contains syntax errors that prevent email servers from properly validating senders. This can cause legitimate emails to fail or allow unauthorised senders.',
    risk: 'Mail servers may ignore or incorrectly interpret the SPF record, effectively providing no protection against email spoofing',
  },
};
