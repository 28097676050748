export default {
  en: {
    title: 'Getting Started',
    tryOutBanner: {
      title: 'Try out Risk Ledger as a client',
      textOne:
        'Risk Ledger is an all-in-one solution to managing your third party risk management processes.',
      textTwo: 'Let’s take you through connecting and evaluating an example supplier',
      link: 'Learn more about the platform',
      trialMode: 'Trial mode',
    },
    checkListItemCard: {
      done: 'Done',
    },
    checklist: {
      title: 'Get started',
      stepOne: {
        title: 'Connect to a supplier',
        text: 'Pick any supplier to connect to to test how you would connect with your suppliers',
      },
      stepTwo: {
        title: 'Review their assessment',
        text: 'Once connected, get instant access to their assessment and see how they score',
      },
      stepThree: {
        title: 'Export the supplier’s risk report',
        text: 'Download a summarised report, ready to be shared with your stakeholders',
      },
      button: 'Begin',
    },
    bookDemo: {
      title: 'Ready to connect to the rest of your suppliers?',
      text: 'Book a demo to learn more about how Risk Ledger can help you manage your third party risk',
      button: 'Book a demo',
    },
    learnMore: {
      title: 'Learn more about our client features',
      learnMore: 'Learn more',
      networkVisualisation: {
        title: 'Network Visualisation',
        text: 'Visualise your supplier network and find concentration risks',
      },
      reporting: {
        title: 'Reporting',
        text: 'Generate customisable reports to share with your stakeholders',
      },
      emergingThreats: {
        title: 'Emerging Threats',
        text: 'Get alerts if your suppliers are impacted by vulnerabilities',
      },
    },
  },
};
