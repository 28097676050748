import type { RouteRecordRaw } from 'vue-router';
import { requireTrialType } from '@/routes/guards/orgtype';
import { requireFlag } from '@/routes/guards/flag';
import { FeatureFlag } from '@/composables/feature-flags';

export default [
  {
    path: 'onboarding',
    name: 'plg-client-onboarding-checklist',
    component: () => import('./ClientOnboardingChecklist.vue'),
    beforeEnter: [
      requireFlag(FeatureFlag.PlgClientOnboardingChecklist),
      requireTrialType('PLG_TRIAL'),
    ],
  },
] satisfies RouteRecordRaw[];
