import { useQuery } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import type { QueryClient } from '@tanstack/vue-query';
import { mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import { useMode } from '@/modules/mode/composables';
import { isAuthenticatedByRLRef } from '@/modules/auth/auth';
import api, { type TrialResponse, type TrialType } from './api';
import { TRIAL_QUERY } from './composableKeys';

const isDateInPast = (date: string) => new Date().getTime() > new Date(date).getTime();

export function useTrial(options?: QueryOptions<TrialResponse | false>, queryClient?: QueryClient) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [TRIAL_QUERY],
      async queryFn() {
        try {
          const res = await api.getTrial();
          return res.data;
        } catch (err) {
          if (err instanceof AxiosError && err.response?.status === 404) return false;
          throw err;
        }
      },
      enabled: isAuthenticatedByRLRef,
    }),
    queryClient,
  );

  const trial = query.data;

  const canInvite = computed(() => {
    // Only active or ended (expired) trials are returned so concluded does not
    // need to be considered. Concluded is the same as not being on a trial.
    if (!trial.value) return true;

    // If the org is on a trial that has ended they are blocked from inviting.
    if (trial.value.trialEndsAt && isDateInPast(trial.value.trialEndsAt)) return false;

    return !trial.value.inviteRestrictions;
  });

  const { currentMode } = useMode();
  function canConnect(orgId: string, managed: boolean) {
    // Connection restrictions only apply to clients
    if (currentMode.value !== 'client') return true;

    // Not on a trial
    if (!trial.value || trial.value.concludedAt) return true;

    // Trial has expired
    if (trial.value.trialEndsAt && isDateInPast(trial.value.trialEndsAt)) return false;

    switch (trial.value.supplierRestrictions) {
      case 'managed':
        return managed;
      case 'approved_list':
        return managed || trial.value?.approvedSuppliers?.includes(orgId);
      default:
        return true;
    }
  }

  const isOnActiveTrial = computed(
    () =>
      !!trial.value &&
      !trial.value.concludedAt &&
      !(trial.value.trialEndsAt && isDateInPast(trial.value.trialEndsAt)),
  );

  const isTrialEnded = computed(
    () =>
      !!trial.value &&
      !trial.value.concludedAt &&
      !!trial.value.trialEndsAt &&
      isDateInPast(trial.value.trialEndsAt),
  );

  const isOnTrialType = (trialType: TrialType) =>
    isOnActiveTrial.value && !!trial.value && trial.value.trialType === trialType;

  return { ...query, trial, canInvite, canConnect, isOnActiveTrial, isTrialEnded, isOnTrialType };
}
