export enum ReassessmentTriggerType {
  ScheduledReassessment = 'SCHEDULED',
  FrameworkRequirementChangeReassessment = 'FRAMEWORK_REQUIREMENT_CHANGE',
}

export type AnswerConfirmation = {
  reassessmentID: string;
  answerID: string;
  confirmed: boolean;
  modifiedAt: string;
  modifiedBy: string;
};

export type ReassessmentStatus = {
  status: string;
  prevCompletedAt: string | null;
  prevCompletedBy: string | null;
  nextDueAt: string | null;
  nextCanStartAt: string | null;
  reassessmentProgress: number;
  triggerType: ReassessmentTriggerType;
  completed: boolean;
  answerConfirmations: AnswerConfirmation[];
};
