import type { InfractionText } from './types';

export const dkimInfractions: Record<string, InfractionText> = {
  DKIM_MISSING: {
    infraction: "No DKIM record exists in the domain's DNS TXT records",
    explanation:
      "DomainKeys Identified Mail (DKIM) allows email servers to verify that messages haven't been tampered with during transit. Without a DKIM record, the domain's emails cannot be cryptographically verified, making them vulnerable to spoofing and modification.",
    risk: 'Emails cannot be cryptographically verified, increasing vulnerability to spoofing and tampering',
  },
  DKIM_KEY_TYPE_MISSING: {
    infraction:
      "DKIM record does not specify the cryptographic algorithm type (default 'rsa' or newer 'ed25519')",
    explanation:
      "The DKIM record lacks a cryptographic algorithm specification (such as 'rsa' or 'ed25519'). This may cause email servers to use incorrect algorithms or fail to validate messages entirely.",
    risk: 'Authentication may fail or use incorrect algorithms, compromising signature verification',
  },
  DKIM_HASH_ALGO_NOT_SET: {
    infraction: 'DKIM record does not specify which hash algorithm to use',
    explanation:
      "The DKIM record doesn't indicate which hash algorithm to use for signatures. This can lead to validation failures or the use of less secure algorithms when verifying messages.",
    risk: 'Signature validation may fail or use incorrect algorithms for message verification',
  },
  DKIM_HASH_ALGO_OUT_OF_DATE: {
    infraction: 'DKIM uses deprecated hash algorithm (e.g., SHA-1) instead of recommended SHA-256',
    explanation:
      'The domain uses an outdated hash algorithm (like SHA-1) instead of the more secure SHA-256. This weakens the cryptographic protection and makes the signatures more vulnerable to attack.',
    risk: 'Weakened cryptographic security, vulnerable to collision attacks',
  },
  DKIM_PUB_KEY_MISSING: {
    infraction: 'DKIM record does not contain the required public key data',
    explanation:
      'The DKIM record lacks the required public key data. Without this, receiving email servers cannot verify the authenticity of messages sent from the domain.',
    risk: 'Receiving servers cannot verify email signatures, breaking authentication',
  },
  DKIM_KEY_TYPE_NOT_RECOMMENDED: {
    infraction: 'DKIM uses key types other than RSA or ED25519',
    explanation:
      'The domain uses key types other than the standard RSA or ED25519. This may cause compatibility problems with email servers and potentially use less secure encryption methods.',
    risk: 'May cause compatibility issues or use less secure cryptographic methods',
  },
  DKIM_BODY_LENGTH_LIMIT_MISSING: {
    infraction: 'DKIM record does not specify maximum signed body length',
    explanation:
      "The DKIM record doesn't define the maximum length of message content that should be signed. This could allow attackers to modify messages by adding content beyond the signed portion.",
    risk: 'Could allow message truncation attacks or cause overflow errors in processing',
  },
};
