import { request } from '@/api/request';

export type TrialType = 'TRIAL' | 'SELF_TRIAL' | 'PLG_TRIAL';

// Get current orgs trial
export type TrialResponse = {
  id: string;
  orgID: string | null;
  createdBy: string;
  createdAt: string;
  trialName: string;
  supplierRestrictions: string;
  inviteRestrictions: boolean;
  approvedSuppliers: string[];
  concludedAt: string | null;
  concludedBy: string | null;
  trialEndsAt: string | null;
  trialType: TrialType;
};

const getTrial = () => request<TrialResponse>('GET', '/trial');

export const live = {
  getTrial,
};

const api = { ...live };
export default api;
