import type { InfractionText } from './types';

export const hstsInfractions: Record<string, InfractionText> = {
  HSTS_MISSING_HEADER: {
    infraction:
      'The website does not send the "Strict-Transport-Security" HTTP header (or max-age is set to 0)',
    explanation:
      'HTTP Strict Transport Security (HSTS) tells browsers to only connect to the domain using secure HTTPS connections. Without this header, browsers may attempt to use insecure HTTP connections, making the site vulnerable to downgrade attacks. Additionally, setting the max-age of your policy to 0 tells the browser to ignore it entirely.',
    risk: 'Without HSTS, your site is vulnerable to SSL stripping attacks (downgrade attacks), man-in-the-middle (MITM) attacks & cookie hijacking',
  },
  HSTS_MISSING_MAX_AGE: {
    infraction:
      "The HSTS header is present but either doesn't contain the required max-age directive or contains a malformed max-age value (non-numeric)",
    explanation:
      "The domain's HSTS policy has a missing or incorrectly formatted max-age value. This prevents browsers from knowing how long they should enforce HTTPS-only connections to the domain.",
    risk: 'Browsers may ignore the entire HSTS policy or HSTS protection might not be enforced',
  },
  HSTS_VERY_SHORT_AGE: {
    infraction: 'HSTS max-age period is set too low',
    explanation:
      "The domain's HSTS policy expires in less than one year (31,536,000 seconds). Short expiration periods create more opportunities for attackers to intercept connections when policies need renewal.",
    risk: 'HSTS protection expires too quickly / More frequent opportunities for attack during policy renewal / Increased risk during the gap between expiration and renewal',
  },
  HSTS_MISSING_SUBDOMAIN: {
    infraction: "HSTS policy doesn't include the includeSubDomains directive",
    explanation:
      "The domain's HSTS policy doesn't include the 'includeSubDomains' directive. This means subdomains can still accept insecure connections, potentially allowing attackers to intercept traffic.",
    risk: 'Subdomains remain vulnerable to attacks / Attackers can exploit weaker subdomains to attack the main domain / Cookie theft through subdomain compromise',
  },
  HSTS_MISSING_PRELOAD: {
    infraction: "HSTS header doesn't include the preload directive",
    explanation:
      "The domain's HSTS policy isn't set up for browser preloading. Without preloading, visitors are vulnerable to connection downgrade attacks during their first visit to the site, before the HSTS policy is received.",
    risk: 'First-visit vulnerability remains',
  },
};
