<script lang="ts" setup>
defineOptions({ name: 'BaseSwitch' });

const value = defineModel<boolean>({ default: false });

withDefaults(
  defineProps<{
    disabled?: boolean;
    label?: string;
  }>(),
  {
    label: undefined,
  },
);
</script>

<template>
  <label class="relative inline-flex items-center" data-testid="base-switch">
    <input
      v-model="value"
      type="checkbox"
      class="peer sr-only"
      data-testid="bs-checkbox"
      :disabled="disabled"
    />
    <div
      class="relative box-border inline-block h-[16px] w-[28px] cursor-pointer rounded-full border border-neutral outline-2 outline-offset-2 transition-colors duration-75 peer-focus-visible:outline dark:border-dark-neutral"
      :class="[
        modelValue
          ? 'border-strong-primary bg-strong-primary-muted dark:border-dark-strong-primary dark:bg-dark-strong-primary-muted'
          : 'bg-neutral dark:bg-dark-neutral',
        {
          'cursor-not-allowed opacity-50': disabled,
        },
      ]"
      data-testid="bs-switch"
    >
      <div
        class="absolute left-[-1px] top-[-1px] box-border h-[16px] w-[16px] rounded-full border border-neutral bg-default transition-transform duration-75 dark:border-dark-neutral dark:bg-dark-default [:checked+div>&]:translate-x-[12px]"
        :class="{
          'border-strong-primary dark:border-dark-strong-primary': modelValue,
        }"
      />
    </div>
    <span v-if="label" class="ml-1.5x font-bold" :class="{ 'text-neutral-disabled': disabled }">
      {{ label }}
    </span>
  </label>
</template>
