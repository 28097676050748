import type { InfractionText } from './types';

export const httpInfractions: Record<string, InfractionText> = {
  // XSS Protection Infractions
  XXSS_NO_MODERN_PROTECTION: {
    infraction: 'No CSP script-src with X-XSS-Protection present',
    explanation:
      'The domain lacks a Content Security Policy script-src directive, relying only on X-XSS-Protection. This older protection mechanism provides limited defense against cross-site scripting attacks.',
    risk: 'Relying only on legacy XSS protection',
  },
  XXSS_LEGACY_ONLY: {
    infraction: 'Using only X-XSS-Protection without CSP',
    explanation:
      'The domain relies solely on the older X-XSS-Protection header without Content Security Policy. While this provides basic protection, it lacks the comprehensive controls offered by modern CSP directives.',
    risk: 'Depending on non-standard, deprecated protection',
  },
  XXSS_CSP_CONFLICT: {
    infraction: 'Inconsistent XSS protection between CSP and X-XSS-Protection',
    explanation:
      'The domain has mismatched settings between X-XSS-Protection header and Content Security Policy. This inconsistency may lead to unreliable XSS protection across different browsers.',
    risk: 'Potential browser confusion in XSS protection',
  },
  XXSS_MULTIPLE_HEADERS: {
    infraction: 'Multiple headers present causing undefined behaviour',
    explanation:
      'The domain sends multiple X-XSS-Protection headers. This creates undefined behaviour as browsers may interpret the conflicting protection settings differently.',
    risk: 'Browsers may ignore protection',
  },
  // Referrer Policy Infractions
  RP_MISSING_HEADER: {
    infraction: 'No Referrer-Policy header present',
    explanation:
      'The domain lacks a Referrer-Policy header. This may lead to unintended information leakage through referrer headers when users navigate away from the site.',
    risk: 'Uncontrolled referrer information leakage',
  },
  RP_CSP_DEPRECATED: {
    infraction: 'Using CSP referrer directive instead of Referrer-Policy header',
    explanation:
      'The domain uses the deprecated CSP referrer directive instead of the current Referrer-Policy header. Modern browsers may ignore this outdated configuration.',
    risk: 'Relying on deprecated CSP referrer directive',
  },
  RP_INSECURE_VALUE: {
    infraction: 'Using unsafe-url or no policy specified',
    explanation:
      "The domain uses an unsafe referrer policy ('unsafe-url') or lacks a specific policy. This may leak sensitive information in referrer headers when users follow links to other sites.",
    risk: 'Full URL paths leaked in cross-origin requests',
  },
  RP_CSP_CONFLICT: {
    infraction: 'Mismatched Referrer-Policy header and CSP referrer directive',
    explanation:
      'The domain has mismatched values between Referrer-Policy header and CSP referrer directive. This inconsistency creates unpredictable referrer behaviour across browsers.',
    risk: 'Inconsistent referrer behaviour across browsers',
  },
  RP_MULTIPLE_HEADERS: {
    infraction: 'Multiple headers present causing undefined behaviour',
    explanation: 'Multiple headers present causing undefined behaviour',
    risk: 'Browsers may apply unexpected policy',
  },
  // X-Frame Options Infractions
  XFO_NO_PROTECTION: {
    infraction: 'Neither X-Frame-Options nor CSP frame-ancestors present',
    explanation:
      'The domain lacks both X-Frame-Options and CSP frame-ancestors directives. This leaves the site vulnerable to clickjacking attacks where it could be embedded in malicious frames.',
    risk: 'Site vulnerable to clickjacking with no protection',
  },
  XFO_LEGACY_ONLY: {
    infraction: 'Using only X-Frame-Options without CSP frame-ancestors',
    explanation:
      'The domain relies solely on the older X-Frame-Options header without CSP frame-ancestors. While this provides basic protection, it lacks the more flexible controls of modern Content Security Policy.',
    risk: 'Depending on deprecated protection that might cease to work',
  },
  XFO_CSP_CONFLICT: {
    infraction: 'Mismatched X-Frame-Options and CSP frame-ancestors values',
    explanation:
      'The domain has mismatched values between X-Frame-Options and CSP frame-ancestors directives. This inconsistency may lead to unreliable frame protection across different browsers.',
    risk: 'Browsers may apply inconsistent protections',
  },
  XFO_MULTIPLE_HEADERS: {
    infraction: 'Multiple headers present causing undefined behaviour',
    explanation:
      'The domain sends multiple X-Frame-Options headers. This creates undefined behaviour as browsers may interpret the conflicting headers differently.',
    risk: 'Browsers may ignore protection entirely',
  },
  // Content Type Options Infractions
  XCTO_MISSING_HEADER: {
    infraction: 'The website does not send the "X-Content-Type-Options" HTTP header',
    explanation:
      "The domain doesn't send the X-Content-Type-Options header. This allows browsers to interpret file types differently than intended, potentially enabling security bypasses.",
    risk: 'MIME-type sniffing possible, content-type confusion attacks',
  },
  XCTO_INVALID_VALUE: {
    infraction: "Using any value other than 'nosniff'",
    explanation:
      "The domain uses an invalid value for X-Content-Type-Options instead of 'nosniff'. This prevents the header from properly protecting against MIME type confusion attacks.",
    risk: 'Browser may ignore header, MIME-type sniffing possible',
  },
  XCTO_MULTIPLE_HEADERS: {
    infraction: 'Multiple headers present causing undefined behaviour',
    explanation: 'Multiple headers present causing undefined behaviour',
    risk: 'Browsers may ignore the protection',
  },
};
