export default {
  en: {
    supplier: {
      title: 'For Suppliers',
      tooltip: 'Click to switch to Supplier mode',
    },
    becomeSupplier: {
      tooltip: 'Click to become a Supplier',
      title: 'Become a Supplier',
      subTitle:
        '<b>Complete the last supplier due diligence assessment you should <br> ever have to do — with Risk Ledger.</b>',
      oldBody:
        'Becoming a supplier on the Risk Ledger platform will allow you to complete one security and  procurement assessment that you can securely and efficiently share with any client that requires you to take part in their third-party risk management programme. <p /> It is completely <b>free</b> to use the supplier side of the Risk Ledger platform so join suppliers like Stripe and Crowdstrike today.',
      body: 'Becoming a supplier on the Risk Ledger platform will allow you to complete one security and  procurement assessment that you can securely and efficiently share with any client that requires you to take part in their third-party risk management programme. <p /> It is completely <b>free</b> to use the supplier side of the Risk Ledger platform so join suppliers like Stripe and Crowdstrike today. Your connected clients will be able to see the names of your own suppliers in their network visualisation. You can disable this at any time in your settings.',
      button: 'Become Supplier',
      requestDemo:
        'I am interested in scheduling a demo and learning more about the Risk Ledger platform as a supplier',
    },
    client: {
      title: 'For Clients',
      tooltip: 'Click to switch to Client mode',
    },
    becomeClient: {
      tooltip: 'Click to become a Client',
      title: 'See Your Supply Chain Security The Way Your Clients Do',
      subTitle: `Since you're already using Risk Ledger as a supplier, why not try the client side? Use the same platform to assess your own suppliers:`,
      features: `<li>Quickly connect to your suppliers - many already on the platform</li>
        <li>Evaluate suppliers using the same assessment framework you are used to</li>
        <li>Collaborate with your team on suppliers reviews</li>
        <li>Visualise the health of your direct supply chain and fourth parties</li>`,
      button: 'Try it out',
      requestDemo:
        'I am interested in scheduling a demo and learning more about the Risk Ledger platform as a client',
    },
    misc: {
      knowledgeBase: 'Knowledge Base',
      knowledgeBaseTooltip: 'Learn more about Suppliers',
      laterButton: 'Maybe later',
      requestDemo: 'Learn more',
      error: 'Failed to set organisation type, please try again.',
      noPermission:
        'Please contact your organisation lead or admin to request access for the {0} side of the Risk Ledger platform.',
    },
    tryItOut: {
      title: "You respond to security reviews. Now assess your suppliers' compliance.",
      cta: 'Try it out',
    },
  },
};
