// NOTE: This enum is ordered.
// These permissions are displayed as a list to our users and are considered ordered.
// Do not change the ordering of existing permissions unless there is a need for it.
// If a new permission is added, it should be added ordered according to the user lifecycle.
// You should also add copy for it in src/modules/roles/i18n.ts

// To hide permissions based on feature flags, check out the useRoleForm composable
enum Permission {
  // Clients
  ClientsFrameworkConfigApply = 'clients.framework_config.apply/edit',
  ClientsFrameworkConfigManage = 'clients.framework_config.manage/admin',
  ClientsConnectionsManage = 'clients.connections.manage/edit',
  ClientsInvitesView = 'clients.invites.view/view',
  ClientsInvitesManage = 'clients.invites.manage/edit',
  ClientsSuppliersView = 'clients.suppliers.view/view',
  ClientsSuppliersManage = 'clients.suppliers.manage/edit',
  ClientsConnectionsRemove = 'clients.connections.remove/edit',
  ClientsConnectionsTogglePrivate = 'clients.connections.toggle_private/admin',
  ClientsPoliciesManage = 'clients.policies.manage/edit',
  ClientsLabelsApply = 'clients.labels.apply/edit',
  ClientsLabelsManage = 'clients.labels.manage/admin',
  ClientsCustomPropertiesSettings = 'clients.custom_properties.settings/admin',
  ClientsCustomPropertiesApply = 'clients.custom_properties.apply/edit',
  ClientsConnectionViewsManage = 'clients.connections.views.manage/edit',
  ClientsTagsApply = 'clients.tags.apply/edit',
  ClientsApprovalChange = 'clients.approval.change/edit',
  ClientsApprovalSettings = 'clients.approval.settings/admin',
  ClientsOverridesApply = 'clients.overrides.apply/edit',
  ClientsRemediationsManage = 'clients.remediations.manage/edit',
  ClientsSuppliersNotesView = 'clients.suppliers.notes.view/view',
  ClientsSuppliersNotesManage = 'clients.suppliers.notes.manage/edit',
  ClientsAssignedUsersApply = 'clients.assigned_users.apply/edit',
  ClientsRisksView = 'clients.risks.view/view',
  ClientsRisksManage = 'clients.risks.manage/edit',
  ClientsRisksNotesAdd = 'clients.risks.notes/edit',
  ClientsRisksSettings = 'clients.risks.settings/admin',
  ClientsDiscussionsSend = 'clients.discussions/edit',
  ClientsFilesDownload = 'clients.files.download/view',
  ClientsReportingView = 'clients.reporting.view/view',
  ClientsInsightsView = 'clients.insights.view/view',
  ClientsInsightsManage = 'clients.insights.manage/edit',
  ClientsNetworkView = 'clients.network.view/view',
  ClientsNetworkSettings = 'clients.network.settings/admin',
  ClientsCommunitiesMessageboardView = 'clients.communities.messageboard.view/view',
  ClientsCommunitiesMessageboardPost = 'clients.communities.messageboard.post/edit',
  ClientsCommunitiesMessageboardManage = 'clients.communities.messageboard.manage/admin',
  ClientsCommunitiesSignalsView = 'clients.communities.signals.view/view',
  ClientsCommunitiesInvitesManage = 'clients.communities.invites.manage/admin',
  ClientsExternalMonitoringView = 'clients.external_monitoring.view/view',
  ClientsExternalMonitoringManage = 'clients.external_monitoring.manage/edit',
  ClientsConnectionProductsManage = 'clients.connections.products.manage/edit',

  // Federated
  FederatedSubEntitiesView = 'federated.sub_entities.view/view',
  FederatedInvitesView = 'federated.invites.view/view',
  FederatedInvitesManage = 'federated.invites.manage/edit',
  FederatedLabelsManage = 'federated.labels.manage/admin',
  FederatedLabelsApply = 'federated.labels.apply/edit',
  FederatedCustomPropertiesSettings = 'federated.custom_properties.settings/admin',
  FederatedCustomPropertiesApply = 'federated.custom_properties.apply/edit',
  FederatedRisksView = 'federated.risks.view/view',
  FederatedRisksManage = 'federated.risks.manage/edit',
  FederatedRisksSettings = 'federated.risks.settings/admin',
  FederatedRisksNotesAdd = 'federated.risks.notes/edit',
  FederatedNetworkView = 'federated.network.view/view',

  // Settings
  SettingsUsersManage = 'settings.users.manage/admin',
  SettingsRolesManage = 'settings.roles.manage/admin',
  SettingsOrgManage = 'settings.org.manage/admin',
  SettingsDiscussionSeen = 'settings.discussion.seen/admin',
  SettingsSsoManage = 'settings.sso.manage/admin',
  SettingsApiKeysManage = 'settings.api_keys.manage/admin',
  SettingsManageMode = 'settings.modes/edit',

  // Suppliers
  SuppliersAssessmentEdit = 'suppliers.assessment.edit/edit',
  SuppliersAssessmentSubmit = 'suppliers.assessment.submit/edit',
  SuppliersFilesDownload = 'suppliers.files.download/view',
  SuppliersConnectionsManage = 'suppliers.connections.manage/edit',
  SuppliersConnectionsRemove = 'suppliers.connections.remove/edit',
  SuppliersClientsView = 'suppliers.clients.view/view',
  SuppliersExternalMonitoringView = 'suppliers.external_monitoring.view/view',
  SuppliersExternalMonitoringManage = 'suppliers.external_monitoring.manage/edit',
  SuppliersProfileSharingView = 'suppliers.profile_sharing.view/view',
  SuppliersProfileSharingManage = 'suppliers.profile_sharing.manage/edit',
  SuppliersRemediationsRespond = 'suppliers.remediations.respond/edit',
  SuppliersDiscussionsSend = 'suppliers.discussions/edit',
  SuppliersEmergingThreatsRespond = 'suppliers.emerging_threats.respond/edit',
  SuppliersMergeRequestsManage = 'suppliers.merge_requests.manage/admin',

  // Zeus
  ZeusCommunitiesView = 'zeus.communities.view',
  ZeusCommunitiesManage = 'zeus.communities.manage',
  ZeusCommunitiesMembers = 'zeus.communities.members',
}

export default Permission;
