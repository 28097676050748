import type { MutationObserverOptions } from '@tanstack/vue-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { useI18n } from 'vue-i18n';
import { until } from '@vueuse/core';
import type { AxiosError } from 'axios';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import { isAuthenticatedByRLRef } from '@/modules/auth/auth';
import type { Organisation } from '@/types/models/organisation';
import api, { type UpdateOrganisationVariables, type UsersResponse } from './api';
import { ORG_QUERY, ORG_USERS_QUERY } from './composableKeys';

export function useCurrentOrg(options?: QueryOptions<Organisation>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [ORG_QUERY],
      async queryFn() {
        const res = await api.getCurrentOrg();
        return res.data;
      },
      staleTime: Infinity,
      enabled: isAuthenticatedByRLRef,
    }),
  );

  const currentOrg = computed(() => query.data.value);

  async function resolvedOrg() {
    if (!isAuthenticatedByRLRef.value) return undefined;
    if (!currentOrg.value) {
      await until(currentOrg).toMatch((c) => !!c);
    }
    if (!currentOrg.value) throw new Error('this is impossible');
    return currentOrg.value;
  }

  /**
   * Waits for organisation to be defined and returns a boolean value.
   */
  const isClient = async () => (await resolvedOrg())?.isClient;
  const isSupplier = async () => (await resolvedOrg())?.isSupplier;
  const isFederated = async () => (await resolvedOrg())?.isFederated;
  const isClientRestricted = async () => (await resolvedOrg())?.isClientRestricted;
  const isRL = computed(() => currentOrg.value?.orgID === import.meta.env.VUE_APP_RL_ORG_ID);

  return {
    ...query,
    currentOrg,
    resolvedOrg,
    isClient,
    isSupplier,
    isFederated,
    isClientRestricted,
    isRL,
  };
}

export function useUpdateCurrentOrg(
  options?: MutationObserverOptions<Organisation, AxiosError, UpdateOrganisationVariables, never>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(user) {
        const res = await api.editCurrentOrg(user);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([ORG_QUERY], data);
      },
    }),
  );
}

export function useBecomeSupplier(
  options?: MutationObserverOptions<void, AxiosError, void, never>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        await api.becomeSupplier();
      },
      ...options,
      onSuccess() {
        queryClient.setQueryData([ORG_QUERY], (org: Organisation) => ({
          ...org,
          isSupplier: true,
        }));
      },
    }),
  );
}

export function useBecomeClient(options?: MutationObserverOptions<void, AxiosError, void, never>) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        await api.becomeClient();
      },
      ...options,
      onSuccess() {
        queryClient.setQueryData([ORG_QUERY], (org: Organisation) => ({
          ...org,
          isClient: true,
          clientAt: new Date().toISOString(),
        }));
      },
    }),
  );
}

export function useOrgUsers(options?: QueryOptions<UsersResponse['usersByID']>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [ORG_USERS_QUERY],
      async queryFn() {
        const res = await api.getOrgUsers();
        return res.data.usersByID;
      },
      enabled: isAuthenticatedByRLRef,
    }),
  );

  const allOrgUsers = computed(() => query.data.value || {});

  const { t } = useI18n();
  function getUserName(userID: string | null | undefined) {
    // Important to check that userID is defined, or if the env var is undefined
    if (userID && userID === import.meta.env.VUE_APP_SYSTEM_USER) {
      return t('global.systemUser');
    }
    return (userID && allOrgUsers.value[userID]?.fullName) || t('global.deletedUser');
  }

  return { ...query, allOrgUsers, getUserName };
}
