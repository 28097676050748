import type { Getter, GetterTree, MutationTree, Module } from 'vuex';
import type { Domain } from '@/types/models/domain';
import type { Control } from '@/types/models/control';
import type { State as RootState } from '@/store/useStore';

/**
 * This store is deprecated in favour of the composables.
 *
 * It largely still exists as it's used in the assessment and profile stores,
 * which can't use the composable.
 */
const state = {
  domains: [] as Domain[],
  controls: [] as Control[],
  frameworkRequiredDomainIDs: [] as string[],
  frameworkRequiredControlIDs: [] as string[],
};
export type State = typeof state;

const mutations: MutationTree<State> = {
  SET_FRAMEWORK(state, framework) {
    state.domains = framework.domains;
    state.controls = framework.controls;
  },
  SET_REQUIRED(state, { requiredDomainIDs, requiredControlIDs }) {
    state.frameworkRequiredDomainIDs = requiredDomainIDs;
    state.frameworkRequiredControlIDs = requiredControlIDs;
  },
};

// Get object of domainIDs mapped to domains
// Use to lookup a domain by its ID
const domainsByID: Getter<State, RootState> = (state) =>
  Object.fromEntries(state.domains.map((domain) => [domain.domainID, domain]));

// Get object of controlIDs mapped to controls
// Use to lookup a control by its ID
const controlsByID: Getter<State, RootState> = (state) =>
  Object.fromEntries(state.controls.map((control) => [control.controlID, control]));

// Get object of domainIDs mapped to array of controlIDs
// Use to lookup controls that are in a particular domain
// Does not include deprecated controls
const controlsByDomain: Getter<State, RootState> = (state, getters) =>
  state.domains.reduce<Record<string, string[]>>((obj, domain) => {
    obj[domain.domainID] = (getters.sortedControls as Control[])
      .filter((control) => control.domainID === domain.domainID && !control.deprecated)
      .map((control) => control.controlID);
    return obj;
  }, {});

const sortedDomains: Getter<State, RootState> = (state) =>
  state.domains
    .concat()
    .sort((a, b) => {
      if (a.letter === b.letter) return 0;
      return a.letter > b.letter ? 1 : -1;
    })
    .filter((domain) => !domain.deprecated);

const sortedControls: Getter<State, RootState> = (state, getters) =>
  state.controls
    .concat()
    .sort((a, b) => {
      const x = getters.domainsByID[a.domainID].letter;
      const y = getters.domainsByID[b.domainID].letter;
      if (x === y) return a.number - b.number;
      return x > y ? 1 : -1;
    })
    .filter((control) => !control.deprecated);

const sortedControlsDeprecated: Getter<State, RootState> = (state, getters) =>
  state.controls
    .concat()
    .sort((a, b) => {
      const x = getters.domainsByID[a.domainID].letter;
      const y = getters.domainsByID[b.domainID].letter;
      if (x === y) return a.number - b.number;
      return x > y ? 1 : -1;
    })
    .filter((c) => c.deprecated || getters.domainsByID[c.domainID].deprecated);

// Get object of parent controlIDs mapped to array of dependent child controlIDs
// Use to lookup dependent control of a parent control
// Does not include deprecated controls
const dependentControls: Getter<State, RootState> = (_state, getters) =>
  (getters.sortedControls as Control[]).reduce<Record<string, string[]>>((obj, c) => {
    if (!c.parentID) return obj;
    if (Array.isArray(obj[c.parentID])) obj[c.parentID]!.push(c.controlID);
    else obj[c.parentID] = [c.controlID];
    return obj;
  }, {});

const getters: GetterTree<State, RootState> = {
  domainsByID,
  controlsByID,
  controlsByDomain,
  sortedDomains,
  sortedControls,
  dependentControls,
  sortedControlsDeprecated,
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
} satisfies Module<State, RootState>;
