import type { RouteRecordRaw } from 'vue-router';

// This guard redirects the user to the new public shared profile path instead of the old path.
// Old path: /p/shared?link={sharedLinkID}
// New path: /p/shared/{sharedLinkID}
function oldPathToNewPath(to: any, _: any, next: any) {
  if (to.query.linkID) next({ name: 'shared-profile', params: { linkId: to.query.linkID } });
  next();
}

export default [
  {
    path: '/p/shared/:linkId?', // link param must be optional to match both the old and new route
    name: 'shared-profile',
    component: () => import('./ProfileSharingPublic.vue'),
    beforeEnter: oldPathToNewPath,
    props: true,
    redirect: { name: 'shared-profile-assessment' },
    children: [
      {
        path: 'assessment',
        name: 'shared-profile-assessment',
        component: () => import('./ProfileSharingPublicMainAssessment.vue'),
        props: true,
      },
      {
        path: 'evidence',
        name: 'shared-profile-evidence',
        component: () => import('./ProfileSharingPublicMainEvidence.vue'),
        props: true,
      },
    ],
  },
] satisfies RouteRecordRaw[];
