import type { InfractionText } from './types';

export const tlsInfractions: Record<string, InfractionText> = {
  TLS_INVALID_CERT: {
    infraction: 'Invalid/Expired Certificate',
    explanation:
      "The domain's SSL certificate is invalid, expired, or not issued by a trusted authority. This prevents secure connections and may cause browsers to show security warnings to visitors.",
    risk: 'No trusted connection can be established, exposing traffic to interception and users to phishing attacks',
  },
  TLS_UNTRUSTED_CHAIN: {
    infraction: 'Untrusted Certificate Chain',
    explanation:
      "The domain's SSL certificate chain is incomplete or contains untrusted certificates. This can cause connection errors and security warnings in some browsers.",
    risk: 'Browsers will display security warnings, potential for MITM attacks',
  },
  TLS_DEPRECATED_VERSION: {
    infraction: 'SSL/TLS Version Deprecated',
    explanation:
      'The domain uses outdated SSL/TLS versions (SSL 2.0, SSL 3.0, TLS 1.0, or TLS 1.1). These older versions have known security vulnerabilities that attackers can exploit.',
    risk: 'Known vulnerabilities in older protocols can be exploited for attacks like POODLE, BEAST, etc.',
  },
  TLS_NO_CAA: {
    infraction: 'Missing CAA Record',
    explanation:
      'The domain lacks Certificate Authority Authorization records in DNS. This allows any certificate authority to issue certificates for the domain.',
    risk: 'Any CA could issue certificates for the domain',
  },
  TLS_CERT_HOSTNAME_MISMATCH: {
    infraction: 'Incorrect Certificate Host',
    explanation:
      "The SSL certificate's hostname doesn't match the domain name. This mismatch triggers security warnings and may indicate a misconfiguration or potential security issue.",
    risk: 'Browsers will display security warnings, potential for MITM attacks',
  },
  TLS_INCOMPLETE_CHAIN: {
    infraction: 'Incomplete Certificate Chain',
    explanation:
      "The domain's SSL certificate chain is missing intermediate certificates. This causes validation failures in some clients, preventing secure connections.",
    risk: 'Some clients may fail to validate the certificate',
  },
  TLS_LONG_CERT_VALIDITY: {
    infraction: 'Long Certificate Validity',
    explanation:
      "The domain's SSL certificate is valid for more than 13 months. This exceeds industry standards and increases the window of exposure if the certificate is compromised.",
    risk: 'Non-compliant with industry standards and longer exposure if compromised',
  },
  TLS_NO_CT: {
    infraction: 'Missing CT Logs',
    explanation:
      "The domain's certificate is not logged in Certificate Transparency logs. This reduces the ability to detect potentially malicious certificates issued for the domain.",
    risk: 'Reduced ability to detect misissued certificates',
  },
  TLS_NO_SNI: {
    infraction: 'Missing SNI',
    explanation:
      "The domain doesn't support Server Name Indication (SNI). This limits the ability to serve multiple secure sites from the same IP address.",
    risk: 'Limited ability to serve multiple secure sites on same IP',
  },
  TLS_NO_LATEST: {
    infraction: 'No Support for Latest TLS Version',
    explanation:
      'The domain does not support the latest version of the TLS protocol (TLS 1.3). This may prevent clients from using the most secure encryption available.',
    risk: 'Missing security and performance improvements',
  },
  TLS_WEAK_CIPHER: {
    infraction: 'Weak cipher suites detected',
    explanation:
      'The domain uses outdated encryption methods that offer inadequate security and can be compromised.',
    risk: 'Weak encryption can be broken, compromising data confidentiality',
  },
  TLS_WEAK_RSA_KEY: {
    infraction: 'Insufficient RSA key length',
    explanation:
      'The RSA encryption key is too short (below 2048 bits), making it easier for attackers to break the encryption.',
    risk: 'Increased risk of key compromise',
  },
  TLS_WEAK_ECC_KEY: {
    infraction: 'Insufficient ECC key length',
    explanation:
      'The ECC encryption key is too short (below 256 bits), making it easier for attackers to break the encryption.',
    risk: 'Increased risk of key compromise',
  },
  TLS_NO_PFS: {
    infraction: 'Missing forward secrecy protection',
    explanation:
      'The domain lacks forward secrecy support, allowing past encrypted communications to be decrypted if the private key is compromised.',
    risk: 'Past communications could be decrypted if private key is compromised',
  },
  TLS_RC4_SUPPORTED: {
    infraction: 'RC4 encryption supported',
    explanation:
      'The domain allows RC4 cipher suites, which are cryptographically weak and can be broken.',
    risk: 'RC4 is cryptographically weak and can be broken',
  },
  TLS_INSECURE_RENEG: {
    infraction: 'Insecure TLS renegotiation allowed',
    explanation:
      'The domain permits insecure TLS renegotiation, enabling man-in-the-middle attacks during connection renegotiation.',
    risk: 'Vulnerable to man-in-the-middle attacks during renegotiation',
  },
  TLS_NO_OCSP_STAPLING: {
    infraction: 'OCSP stapling not enabled',
    explanation:
      "The domain doesn't use OCSP stapling, causing slower certificate validation and potential privacy issues.",
    risk: 'Slower certificate validation and potential privacy leaks',
  },
};
