import {
  useMutation,
  useQuery,
  useQueryClient,
  type MutationObserverOptions,
} from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import type {
  QuickAnswerConfig,
  QuickAnswerJob,
  QuickAnswerSuggestion,
} from '@/types/models/quickAnswer';
import {
  NEW_IN_SCOPE_DOMAINS_QUERY,
  QUICK_ANSWER_CONFIG_QUERY,
  QUICK_ANSWER_JOBS_QUERY,
  QUICK_ANSWER_SUGGESTIONS_QUERY,
} from '../composableKeys';
import api from './api';

export const useQuickAnswerConfigQueryFn = async () => {
  const res = await api.fetchQuickAnswerConfig();
  return res.data;
};

export function useQuickAnswerConfig(options?: QueryOptions<QuickAnswerConfig>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [QUICK_ANSWER_CONFIG_QUERY],
      queryFn: useQuickAnswerConfigQueryFn,
    }),
  );

  return { ...query, config: query.data };
}

export function useOptInQuickAnswer(
  options?: MutationObserverOptions<QuickAnswerConfig, AxiosError, void, never>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        const res = await api.optInQuickAnswer();
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([QUICK_ANSWER_CONFIG_QUERY], data);
      },
    }),
  );

  return mutation;
}

export function useOptOutQuickAnswer(
  options?: MutationObserverOptions<QuickAnswerConfig, AxiosError, void, never>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        const res = await api.optOutQuickAnswer();
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([QUICK_ANSWER_CONFIG_QUERY], data);
      },
    }),
  );

  return mutation;
}

export function useRegenerateSuggestions(
  options?: MutationObserverOptions<QuickAnswerJob, AxiosError, void, never>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        const res = await api.regenerateSuggestions();
        return res.data;
      },
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: [QUICK_ANSWER_JOBS_QUERY] });
        queryClient.invalidateQueries({ queryKey: [NEW_IN_SCOPE_DOMAINS_QUERY] });
      },
    }),
  );
}

export function useCompletePrevAssessmentStepQuickAnswer(
  options?: MutationObserverOptions<QuickAnswerConfig, AxiosError, boolean, never>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    mergeMutationOptions(options, {
      async mutationFn(addedPrevQuestionnaire) {
        const res = await api.completePrevAssessmentStepQuickAnswer(addedPrevQuestionnaire);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([QUICK_ANSWER_CONFIG_QUERY], data);
      },
    }),
  );

  return mutation;
}

export function useCompleteEvidenceStepQuickAnswer(
  options?: MutationObserverOptions<QuickAnswerConfig, AxiosError, void, never>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    mergeMutationOptions(options, {
      async mutationFn() {
        const res = await api.completeEvidenceStepQuickAnswer();
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData([QUICK_ANSWER_CONFIG_QUERY], data);
      },
    }),
  );

  return mutation;
}

export function useQuickAnswerJobs(options?: QueryOptions<Array<QuickAnswerJob>>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [QUICK_ANSWER_JOBS_QUERY],
      queryFn: async () => {
        const res = await api.fetchQuickAnswerJobs();
        return res.data;
      },
    }),
  );

  const jobs = computed(() => query.data.value ?? []);

  const haveIncompleteJobs = computed(() => jobs.value.some((job) => job.status !== 'completed'));

  // True if there is at least 1 job and all jobs are complete
  const allJobsComplete = computed(
    () => jobs.value.length > 0 && jobs.value.every((job) => job.status === 'completed'),
  );

  return { ...query, jobs, haveIncompleteJobs, allJobsComplete };
}

export function useQuickAnswerSuggestions(
  options?: QueryOptions<Record<string, QuickAnswerSuggestion>>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [QUICK_ANSWER_SUGGESTIONS_QUERY],
      queryFn: async () => {
        const res = await api.fetchQuickAnswerSuggestions();
        return res.data;
      },
    }),
  );

  const suggestions = computed(() => query.data.value ?? {});

  return { ...query, suggestions };
}

export function useApplySuggestionStatus(
  options?: MutationObserverOptions<QuickAnswerSuggestion, AxiosError, string, never>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(suggestionID) {
        const res = await api.applyQuickAnswerSuggestion(suggestionID);
        return res.data;
      },
      onSuccess(data) {
        queryClient.setQueryData(
          [QUICK_ANSWER_SUGGESTIONS_QUERY],
          (oldData: Record<string, QuickAnswerSuggestion>) =>
            ({
              ...oldData,
              [data.controlId]: data,
            }) satisfies Record<string, QuickAnswerSuggestion>,
        );
      },
    }),
  );
}
