import type { InfractionText } from './types';

export const cspInfractions: Record<string, InfractionText> = {
  CSP_MISSING_HEADER: {
    infraction: 'The website does not implement any Content Security Policy',
    explanation:
      "A Content Security Policy (CSP) controls which resources can load and execute on the domain's pages. Without a CSP, there are no restrictions on what content can run on pages, leaving them vulnerable to cross-site scripting and other injection attacks.",
    risk: 'Site is vulnerable to XSS attacks, clickjacking, and other injection attacks. No restrictions on resource loading',
  },
  CSP_UNSAFE_INLINE_SCRIPT: {
    infraction: "Policy contains 'unsafe-inline' in script-src directive",
    explanation:
      "The domain's CSP includes 'unsafe-inline' in the script-src directive. This allows any inline scripts to run, potentially enabling attackers to inject malicious code directly into pages.",
    risk: 'Allows execution of inline scripts and event handlers, enabling XSS attacks',
  },
  CSP_UNSAFE_EVAL: {
    infraction: "Policy contains 'unsafe-eval' in script-src directive",
    explanation:
      "The domain's CSP includes 'unsafe-eval' in the script-src directive. This allows potentially dangerous runtime code evaluation, which attackers could exploit to run malicious scripts.",
    risk: 'Allows use of eval() and similar dynamic code execution methods, increasing attack surface',
  },
  CSP_MISSING_DEFAULT_SRC: {
    infraction: 'No default-src or fallback directive specified',
    explanation:
      "The domain's CSP lacks a default-src or fallback directive. Without this baseline policy, some resource types may have no loading restrictions by default.",
    risk: 'Leaves potential gaps in resource loading restrictions for unspecified directive types',
  },
  CSP_WILDCARD_SOURCE: {
    infraction: 'Policy contains * in critical directives',
    explanation:
      "The domain's CSP uses '*' wildcards in critical directives. This allows resources to be loaded from any source, defeating the purpose of content restrictions.",
    risk: 'Allows loading resources from any origin, potentially enabling malicious resource injection',
  },
  CSP_MISSING_FRAME_PROTECTION: {
    infraction: 'No frame-ancestors directive specified',
    explanation:
      "The domain's CSP lacks a frame-ancestors directive. This leaves the site vulnerable to clickjacking attacks where it could be embedded in malicious frames.",
    risk: 'Site may be vulnerable to clickjacking attacks',
  },
  CSP_INSECURE_SCHEMES: {
    infraction: 'Policy allows http: or other insecure schemes',
    explanation:
      "The domain's CSP allows resources to load over insecure HTTP connections. This could allow attackers to intercept or modify content before it reaches users.",
    risk: 'Enables loading resources over insecure connections, risking MITM attacks',
  },
  CSP_MISSING_REPORTING: {
    infraction: 'No reporting configuration for CSP violations',
    explanation:
      "The domain's CSP has no reporting configuration. This means security violations won't be reported, making it harder to detect and respond to attacks.",
    risk: 'Unable to monitor and detect potential attacks or policy violations',
  },
  CSP_MIXED_CONTENT: {
    infraction: 'Policy allows mixed active content',
    explanation:
      "The domain's CSP allows mixed active content. This means secure HTTPS pages can load active content over insecure HTTP connections, creating security vulnerabilities.",
    risk: 'Enables loading of active content over insecure connections',
  },
  CSP_PERMISSIVE_SOURCES: {
    infraction: 'Too many external domains allowed in sources',
    explanation:
      "The domain's CSP allows too many external domains as valid sources. This broad allowlist makes it harder to prevent malicious content from loading.",
    risk: 'Increases attack surface through numerous trusted external sources',
  },
  CSP_MISSING_UPGRADE: {
    infraction: 'No upgrade-insecure-requests directive',
    explanation:
      "The domain's CSP lacks the upgrade-insecure-requests directive. This means resources might load over insecure connections when secure ones are available.",
    risk: 'Resources might load over insecure connections when HTTPS is available',
  },
  CSP_NO_NONCE_HASH: {
    infraction: 'No nonces or hashes used for inline scripts',
    explanation:
      "The domain's CSP doesn't use nonces or hashes to validate inline scripts. This makes it harder to safely allow specific trusted inline scripts while blocking malicious ones.",
    risk: 'Relies on unsafe-inline or complete blocking of inline scripts',
  },
  CSP_INCONSISTENT_DIRECTIVES: {
    infraction: 'Conflicting or redundant directive configurations',
    explanation:
      "The domain's CSP contains conflicting or redundant directives. This can create confusion about which rules apply and may leave security gaps.",
    risk: 'May cause browser confusion or unintended policy applications',
  },
};
