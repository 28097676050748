import type { InfractionText } from './types';

export const dmarcInfractions: Record<string, InfractionText> = {
  DMARC_MISSING: {
    infraction: "No DMARC record exists in the domain's DNS records",
    explanation:
      'Domain-based Message Authentication, Reporting, and Conformance (DMARC) tells receiving servers how to handle emails that fail authentication checks. Without a DMARC record, the domain has no policy enforcement for failed authentications and no visibility into potential email abuse.',
    risk: 'No policy enforcement for failed authentication, no visibility into email abuse, and no protection against domain spoofing',
  },
  DMARC_PCT_LT_100: {
    infraction: 'DMARC percentage tag (pct) is set to less than 100%',
    explanation:
      "The domain's DMARC percentage setting is less than 100%. This means only some messages that fail authentication are subject to the policy, creating inconsistent protection against email spoofing.",
    risk: 'Only a portion of failed authentications are subject to the policy, leaving gaps in protection',
  },
  DMARC_P_NONE: {
    infraction: "DMARC policy is set to 'none', providing monitoring only without enforcement",
    explanation:
      "The domain's DMARC policy is set to 'none', which only monitors email authentication failures without taking action. This allows potentially fraudulent emails to be delivered while only collecting reports about the activity.",
    risk: 'Failed authentications are only monitored but not acted upon, allowing potentially fraudulent emails to be delivered',
  },
  DMARC_ASPF_R: {
    infraction: 'DMARC SPF alignment mode (aspf) is set to relaxed instead of strict',
    explanation:
      'The domain uses relaxed SPF alignment in its DMARC configuration. This allows partial domain matches when verifying SPF results, which may reduce the effectiveness of email authentication.',
    risk: 'Allows partial domain matches in SPF verification, potentially reducing security effectiveness',
  },
  DMARC_ADKIM_R: {
    infraction: 'DMARC DKIM alignment mode (adkim) is set to relaxed instead of strict',
    explanation:
      'The domain uses relaxed DKIM alignment in its DMARC configuration. This allows partial domain matches when verifying DKIM signatures, potentially making it easier for unauthorized senders to pass authentication.',
    risk: 'Allows partial domain matches in DKIM verification, potentially reducing security effectiveness',
  },
};
