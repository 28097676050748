export default {
  en: {
    hubSpotModal: {
      title: 'Achieve even more with your Risk Ledger account',
      description:
        "You've been using Risk Ledger to demonstrate your security controls to your clients, but did you know that you can also use Risk Ledger to assure your own suppliers?",
      firstName: 'First name',
      lastName: 'Last name',
      phoneNumber: 'Mobile phone number',
      email: 'Email',
      jobTitle: 'Job title',
      currentTooling: 'Current third party risk tooling',
      consent: 'I agree to receive other communications from Risk Ledger',
      legal1:
        'You may unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.',
      legal2:
        'By clicking submit below, you consent to allow Risk Ledger to store and process the personal information submitted above to provide you the content requested.',
      success: 'Thanks for submitting the form. A member of the team will be in touch shortly',
      error: 'Failed to submit form - please try again',
    },
  },
};
