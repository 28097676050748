import type { NavigationGuard } from 'vue-router';
import { isAuthenticated, isAuthenticatedByRL } from '@/modules/auth/auth';

export const requireAuth: NavigationGuard = async (to, _from, next) => {
  // if authenticated check if user has access to mfa preference pages
  const isAuthMFA = await isAuthenticatedByRL();
  if (!isAuthMFA) {
    if (to.fullPath.length > 1) {
      localStorage.setItem('redirectQuery', to.fullPath);
    }

    next({ name: 'mfa-setup' });
    return;
  }

  next();
};

export const requireAuthWithoutMFAPreference: NavigationGuard = async (_to, _from, next) => {
  // if authenticated check if user has access to mfa preference pages
  const isAuthNoMFA = await isAuthenticated();
  const isAuthMFA = await isAuthenticatedByRL();
  if (!isAuthNoMFA && !isAuthMFA) {
    next({ name: 'login' });
    return;
  }

  if (isAuthMFA) {
    next('/');
    return;
  }

  next();
};

export const requireNoAuth: NavigationGuard = async (_to, _from, next) => {
  const isAuth = await isAuthenticated();
  if (isAuth) {
    next('/');
    return;
  }
  next();
};
